import { render, staticRenderFns } from "./about-image-section.vue?vue&type=template&id=186d14aa&scoped=true"
import script from "./about-image-section.vue?vue&type=script&lang=js"
export * from "./about-image-section.vue?vue&type=script&lang=js"
import style0 from "./about-image-section.vue?vue&type=style&index=0&id=186d14aa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "186d14aa",
  null
  
)

export default component.exports