<template>
  <div class="home-container">
    <app-navbar4 rootClassName="navbar4-root-class-name"></app-navbar4>
    <div class="home-container1 thq-section-max-width">
      <div class="home-header30 thq-section-padding">
        <div class="home-content">
          <div class="home-container2">
            <h1 class="home-text thq-heading-1">
              <span>Unlocking wealth,</span>
              <br />
              <span>embracing possibilities</span>
              <br />
            </h1>
            <p class="home-text05 thq-body-large">
              <span>
                Join an exclusive community and start your journey towards a 
              </span>
              <span>
                diverse and rewarding portfolio of alternative assets like
              </span>
              <span class="home-text08">whiskey casks</span>
              <span class="home-text09">
                ,
                <span v-html="rawqdmz"></span>
              </span>
              <span class="home-text10">fine art</span>
              <span class="home-text11">
                ,
                <span v-html="rawrxwm"></span>
              </span>
              <span class="home-text12">classic cars</span>
              <span class="home-text13">
                ,
                <span v-html="rawss5a"></span>
              </span>
              <span class="home-text14">precious metals</span>
              <span class="home-text15">
                and
                <span v-html="rawkfsj"></span>
              </span>
              <span class="home-text16">fine wines</span>
            </p>
          </div>

          <div class="home-actions">
            <router-link to="/download" class="home-button thq-button-filled">
              <span class="home-text17 thq-body-small">Download Now</span>
            </router-link>
            <router-link to="/about-us" class="home-button1 thq-button-outline">
              <span class="home-text18 thq-body-small">About Yubaro</span>
            </router-link>
          </div>
        </div>
        <div id="homeTop" class="home-container3 thq-section-max-width">
          <img alt="image" src="/yubaro-slash-200w.webp" class="home-image"  fetchpriority="high"  />

          <img
			fetchpriority="high"
            alt="Yubaro app on a desk"
            srcset="/hero-image-1400w.webp 1200w, /hero-image-tablet.webp 800w, /hero-image-mobile.webp 480w"
            sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
            src="/hero-image-1400w.webp" 

            class="home-image1"
          />
        </div>
      </div>
    </div>
    <app-features></app-features>
    <contact-us-bottom
      rootClassName="contact-us-bottom-root-class-name"
    ></contact-us-bottom>
    <app-footer></app-footer>
    <cookie-consent-banner
      rootClassName="cookie-consent-banner-root-class-name"
    ></cookie-consent-banner>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AppFeatures from '../components/app-features'
import ContactUsBottom from '../components/contact-us-bottom'
import AppFooter from '../components/footer'
import CookieConsentBanner from '../components/cookie-consent-banner'

export default {
  name: 'Home',
  props: {},
  components: {
    AppNavbar4,
    AppFeatures,
    ContactUsBottom,
    AppFooter,
    CookieConsentBanner,
  },
  data() {
    return {
      rawqdmz: ' ',
      rawrxwm: ' ',
      rawss5a: ' ',
      rawkfsj: ' ',
    }
  },
  metaInfo: {
    title: 'Yubaro - Alternative Assets Marketplace',
    meta: [
      {
        name: 'description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        property: 'og:title',
        content: 'Yubaro - Alternative Assets Marketplace',
      },
      {
        property: 'og:description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #e0d5bb;
}
.home-container1 {
  width: 100%;
  height: 394px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-header30 {
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
.home-content {
  height: 100%;
  display: flex;
  z-index: 50;
  align-self: flex-start;
  margin-top: 0px;
  align-items: stretch;
  margin-left: 0px;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: space-between;
}
.home-container2 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-text {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  font-size: 44px;
  font-style: normal;
  text-align: left;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 0px;
}
.home-text05 {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  width: 509px;
  height: auto;
  font-size: 16px;
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  font-family: "Poppins";
  margin-bottom: var(--dl-space-space-unit);
}
.home-text08 {
  font-weight: 700;
}
.home-text09 {
  font-weight: 700;
}
.home-text10 {
  font-weight: 700;
}
.home-text11 {
  font-weight: 700;
}
.home-text12 {
  font-weight: 700;
}
.home-text13 {
  font-weight: 700;
}
.home-text14 {
  font-weight: 700;
}
.home-text15 {
  font-weight: 700;
}
.home-text16 {
  font-weight: 700;
}
.home-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  margin-left: var(--dl-space-space-unit);
  padding-top: 0px;
  padding-bottom: var(--dl-space-space-twounits);
}
.home-button {
  text-decoration: none;
}
.home-text17 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 400;
}
.home-button1 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  border-width: 0px;
  text-decoration: none;
  background-image: linear-gradient(90deg, rgba(163,89,27,1) 0%, rgba(241,184,96,1) 58%, rgba(244,188,98,1) 74%, rgba(199,130,96,1) 100%);;
}
.home-text18 {
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 500;
}
.home-container3 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image {
  top: 24px;
  left: 0px;
  width: 200px;
  z-index: 40;
  position: absolute;
  object-fit: cover;
}
.home-image1 {
  width: 682px;
  height: 100%;
  align-self: flex-end;
  object-fit: cover;
}
@media(max-width: 1600px) {
  .home-text {
    fill: var(--dl-color-yubarobrandcolours-primarytext);
    color: var(--dl-color-yubarobrandcolours-primarytext);
    font-style: normal;
    font-weight: 400;
  }
  .home-text05 {
    color: var(--dl-color-yubarobrandcolours-primarytext);
    font-size: 16px;
    font-family: Poppins;
  }
  .home-text08 {
    font-weight: 700;
  }
}
@media(max-width: 1200px) {
  .home-container1 {
    height: 380px;
  }
  .home-text {
    fill: var(--dl-color-yubarobrandcolours-white);
    color: var(--dl-color-yubarobrandcolours-white);
  }
  .home-text05 {
    fill: var(--dl-color-yubarobrandcolours-white);
    color: var(--dl-color-yubarobrandcolours-white);
    width: 511px;
    margin-right: 0px;
  }
  .home-actions {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-container3 {
    background-color: var(--dl-color-yubarobrandcolours-primarytext);
  }
  .home-image1 {
    width: 100%;
    height: 100%;
    opacity: 0.6;
  }
}
@media(max-width: 991px) {
  .home-container1 {
    height: auto;
  }
  .home-text {
    fill: var(--dl-color-yubarobrandcolours-white);
    color: var(--dl-color-yubarobrandcolours-white);
    text-align: left;
  }
  .home-text05 {
    fill: var(--dl-color-yubarobrandcolours-white);
    color: var(--dl-color-yubarobrandcolours-white);
    width: 569px;
    height: auto;
    text-align: left;
    margin-right: 0px;
  }
  .home-container3 {
    background-color: var(--dl-color-yubarobrandcolours-primarytext);
  }
  .home-image {
    top: 38px;
    left: 0px;
    position: absolute;
  }
  .home-image1 {
    width: 100%;
    height: 100%;
  }
}
@media(max-width: 767px) {
  .home-container1 {
    height: auto;
  }
  .home-content {
    width: auto;
    height: 100%;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: 0px;
  }
  .home-text {
    width: 425px;
    font-size: 35px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    line-height: 1;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text05 {
    fill: var(--dl-color-yubarobrandcolours-white);
    color: var(--dl-color-yubarobrandcolours-white);
    width: 402px;
    font-size: 18px;
    font-style: normal;
    margin-top: 0px;
    text-align: left;
    font-weight: 300;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
  }
  .home-actions {
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container3 {
    height: 100%;
    background-color: var(--dl-color-yubarobrandcolours-primarytext);
  }
  .home-image {
    top: 16px;
    left: 25px;
    width: 125px;
    height: 128px;
    position: absolute;
  }
  .home-image1 {
    height: 100%;
    padding-bottom: 0px;
  }
}
@media(max-width: 479px) {
  .home-container1 {
    height: 100%;
    padding-bottom: 0px;
  }
  .home-header30 {
    flex: 1;
    height: 100%;
    max-width: 100%;
    min-width: 100%;
    max-height: 600px;
    min-height: 380px;
    padding-left: 0px;
  }
  .home-content {
    gap: 0;
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 0px;
    max-height: 600px;
    min-height: 380px;
    margin-left: 0px;
    padding-top: var(--dl-space-space-twounits);
    margin-right: 0px;
    padding-left: var(--dl-space-space-fourunits);
    margin-bottom: 0px;
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-text {
    width: auto;
    font-size: 30px;
    text-align: left;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text05 {
    fill: var(--dl-color-yubarobrandcolours-white);
    color: var(--dl-color-yubarobrandcolours-white);
    width: 100%;
    height: auto;
    font-size: 17px;
    font-style: normal;
    margin-top: 0px;
    text-align: left;
    font-weight: 300;
    padding-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .home-actions {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    margin-left: 0px;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
    justify-content: flex-end;
  }
  .home-button {
    width: 100%;
  }
  .home-text17 {
    fill: var(--dl-color-yubarobrandcolours-white);
    color: var(--dl-color-yubarobrandcolours-white);
  }
  .home-button1 {
    width: 100%;
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text18 {
    font-style: normal;
    font-weight: 400;
  }
  .home-container3 {
    height: 100%;
    background-color: var(--dl-color-yubarobrandcolours-primarytext);
  }
  .home-image {
    top: 10px;
    left: 26px;
    width: 85px;
    height: 86px;
    position: absolute;
  }
  .home-image1 {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    margin-bottom: 0px;
    animation-name: fadeInRight;
    padding-bottom: 0px;
    animation-delay: 0s;
    animation-duration: 500ms;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }
}
</style>
