<template>
  <div class="download-container">
    <app-navbar4 rootClassName="navbar4-root-class-name9"></app-navbar4>
    <div class="thq-section-padding">
      <div class="download-max-width thq-section-max-width">
        <div class="thq-flex-row download-container1">
          <div class="download-column">
            <div class="download-content">
              <h1 class="download-heading1 thq-heading-1">Download our App</h1>
              <p class="download-content1 thq-body-large">
                You can download our app on the Apple App Store, Google Play
                Store, or you can access it through any web browser:
              </p>
            </div>
            <div class="download-actions">
              <a
                href="https://apps.apple.com/us/app/yubaro/id6502051801"
                target="_blank"
                rel="noreferrer noopener"
                class="download-button thq-button-filled"
              >
                <span class="download-action1 thq-body-small">
                  <span>Download on the Apple App Store</span>
                  <br />
                </span>
              </a>
            </div>
            <a
              href="https://play.google.com/store/apps/details?id=com.yubaro.clientapp"
              target="_blank"
              rel="noreferrer noopener"
              class="download-button1 thq-button-filled"
            >
              <span class="download-action11 thq-body-small">
                <span>Download on the Google Play Store</span>
                <br />
              </span>
            </a>
            <a
              href="https://app.yubaro.io/"
              target="_blank"
              rel="noreferrer noopener"
              class="download-button2 thq-button-outline"
            >
              <span class="download-action2 thq-body-small">
                Access it on the web
              </span>
            </a>
          </div>
          <div class="download-container2">
            <img
              alt="PlaceholderImage1314"
              srcset="features//offer-page-1400w.webp 1200w, features/offer-page-tablet.webp 800w, features/offer-page-mobile.webp 480w"
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/features/offer-page-1400w.webp"
              class="download-image1 thq-img-ratio-4-3"
            />
          </div>
        </div>
      </div>
    </div>
    <contact-us-bottom></contact-us-bottom>
    <app-footer></app-footer>
    <cookie-consent-banner
      rootClassName="cookie-consent-banner-root-class-name4"
    ></cookie-consent-banner>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import ContactUsBottom from '../components/contact-us-bottom'
import AppFooter from '../components/footer'
import CookieConsentBanner from '../components/cookie-consent-banner'

export default {
  name: 'Download',
  props: {},
  components: {
    AppNavbar4,
    ContactUsBottom,
    AppFooter,
    CookieConsentBanner,
  },
  metaInfo: {
    title: 'Download - Yubaro',
    meta: [
      {
        name: 'description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        property: 'og:title',
        content: 'Download - Yubaro',
      },
      {
        property: 'og:description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
    ],
  },
}
</script>

<style scoped>
.download-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #e0d5bb;
}
.download-max-width {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.download-column {
  gap: 24px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.download-content {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.download-heading1 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 1.1;
}
.download-content1 {
  font-family: "Poppins";
  line-height: 1.1;
}
.download-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.download-button {
  text-decoration: none;
}
.download-action1 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
}
.download-button1 {
  text-decoration: none;
}
.download-action11 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
}
.download-button2 {
  text-decoration: none;
}
.download-action2 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
}
.download-container2 {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 35%;
  justify-content: flex-start;
}
.download-image1 {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  position: absolute;
  flex-grow: 1;
  object-fit: cover;
}
@media(max-width: 991px) {
  .download-container1 {
    flex-direction: column;
  }
  .download-container2 {
    width: 100%;
    padding-top: 56.25%;
  }
}
@media(max-width: 767px) {
  .download-column {
    align-items: center;
  }
  .download-heading1 {
    align-self: center;
    text-align: center;
  }
  .download-content1 {
    text-align: center;
  }
  .download-actions {
    width: 100%;
    justify-content: center;
  }
  .download-image1 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .download-heading1 {
    font-size: 36px;
  }
  .download-content1 {
    line-height: 1.3;
  }
  .download-actions {
    flex-direction: column;
  }
  .download-button {
    width: 100%;
  }
  .download-button1 {
    width: 100%;
  }
  .download-button2 {
    width: 100%;
  }
}
</style>
