<template>
  <div class="capital-risk-disclosure-container">
    <app-navbar4 rootClassName="navbar4-root-class-name8"></app-navbar4>
    <div class="capital-risk-disclosure-container1 thq-section-padding">
      <div class="capital-risk-disclosure-max-width thq-section-max-width">
        <h2
          id="capitalRiskH"
          class="capital-risk-disclosure-heading1 thq-heading-2"
        >
          Your Capital is at Risk
        </h2>
        <div class="capital-risk-disclosure-container2">
          <p class="capital-risk-disclosure-content1 thq-body-small">
            <br />
            <span>
              WealthExpress Limited (Company number 13361525) acts an introducer
              and asset broker between asset providers and prospective clients and
              does not provide financial, legal or tax advice or any other
              professional advice.
              <span v-html="raw9uv6"></span>
            </span>
            <br />
            <br />
            <span>
              WealthExpress Limited (herein after known as &quot;Yubaro&quot;)
              does not act on behalf of or represent any investment provider as a
              &quot;promoter&quot; for the purposes of Section 21 of the FSMA
              guidelines. The firm does not accept any responsibility for choices
              made freely by a client following the introduction of products and
              services provided by the investment provider. Should a client
              require professional investment advice relating to financial, legal
              or tax information, then this is the sole responsibility of the
              client and not Yubaro. By accepting the Terms and Conditions, the
              disclaimer and the introduction to the third-party investment
              provider, the risks of any purchase are considered to be clearly
              understood by the client in its entirety and without exception or
              severance or any of its constituent parts.
            </span>
            <br />
            <br />
            <span>
              Yubaro accepts no liability what so ever and how so ever caused for
              losses arising indirectly or directly from use of any information
              provided on this site and other company documents. Yubaro gives
              notice that it offers no guarantees as to any losses what so ever or
              how so ever caused or for a return on investment (here in after
              referred to as &quot;ROI&quot;) once a client independently chooses
              an investment provider. Previous results of investments are not a
              predictor of a return on investment outcome. Any ROI may be affected
              by exchange rates to which no guarantees can be given. There may be
              a loss of capital or no ROI as any capital investment is at the risk
              of the client’s choice(s) (whether or not financial advice has been
              taken and whether or not Yubaro offered to provide or did provide
              the details of an authorised financial adviser). Furthermore as an
              explanatory note – if the client chooses an investment that is not
              regulated by the Financial Conduct Authority (here in after referred
              to as &quot;FCA&quot;) that investment does not receive the legal
              protection guarantee under the Financial Services Compensation
              Scheme (here in after referred to as &quot;FSCS&quot;) from losses
              what so ever and how so ever caused and no complaint can be made to
              the Financial Ombudsman Service. It is drawn to your attention that
              only cash savings accounts in the UK are protected FSCS.
            </span>
            <br />
            <br />
            <span>
              Yubaro is not authorised, controlled or regulated by the Financial
              Conduct Authority (FCA) as per the Financial Services and Markets
              Act 2000 (herein after referred to as ‘FSMA’) and for the avoidance
              of doubt, any information and content on this site should not be
              accepted and is not intended to be held out as a ‘financial
              promotion’ as described for the purposes of Section 21 in the FSMA
              guidelines. Such information should not therefore be used as a basis
              for any passion purchase or investment and no responsibility is
              accepted for an investor or third-party losses.
              <span v-html="raw4m8h"></span>
            </span>
            <br />
            <br />
            <span>
              The information held on this site and in any other company
              documentation, whether made expressly, orally or implied is
              therefore for information only and is subject to change without
              notice. Such information is not and shall not be held out to act as
              or offer financial advice or should be interpreted as financial
              advice or any other type of professional advice by a prospective
              client. Information on passion purchases must be used responsibly
              and each prospective client must use their own personal discretion
              and judgment after receiving financial advice from a regulated
              financial adviser when choosing any of these products or services.
              <span v-html="rawgouz"></span>
            </span>
            <br />
            <br />
            <span>
              Information content in this site is correct at the date and time of
              publication. No warranty is made as to any information content
              relating to its accuracy, certainty or its reliability, made either
              by reference to implied or express statements how so ever made
              without any exceptions. Also no responsibility shall apply if there
              are any delays, errors, omissions, interruptions and the like to the
              site or its information how so ever stored, recorded or published.
            </span>
            <br />
            <br />
            <span>
              A user of this site also accepts that there is no warranty from any
              damage caused by errors, computer viruses, and such other malware.
            </span>
            <br />
            <br />
            <span>
              A user of this site does not enter into or constitute an agreement
              to any contract between Yubaro and the site user and no duties,
              liabilities or responsibilities shall attach to such use of the site
              or any information contained therein. For the avoidance of doubt no
              contract is either intended or meant to exist between Yubaro and a
              site user and/or prospective client or third party.
            </span>
            <br />
          </p>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
    <cookie-consent-banner
      rootClassName="cookie-consent-banner-root-class-name2"
    ></cookie-consent-banner>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AppFooter from '../components/footer'
import CookieConsentBanner from '../components/cookie-consent-banner'

export default {
  name: 'CapitalRiskDisclosure',
  props: {},
  components: {
    AppNavbar4,
    AppFooter,
    CookieConsentBanner,
  },
  data() {
    return {
      raw9uv6: ' ',
      raw4m8h: ' ',
      rawgouz: ' ',
    }
  },
  metaInfo: {
    title: 'Capital Risk Disclosure - Yubaro',
    meta: [
      {
        name: 'description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        property: 'og:title',
        content: 'Capital Risk Disclosure - Yubaro',
      },
      {
        property: 'og:description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
    ],
  },
}
</script>

<style scoped>
.capital-risk-disclosure-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #e0d5bb;
}
.capital-risk-disclosure-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.capital-risk-disclosure-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.capital-risk-disclosure-heading1 {
  align-self: flex-start;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.capital-risk-disclosure-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.capital-risk-disclosure-content1 {
  font-family: "Poppins";
}
@media(max-width: 767px) {
  .capital-risk-disclosure-heading1 {
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .capital-risk-disclosure-content1 {
    font-family: Poppins;
  }
}
</style>
