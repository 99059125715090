<template>
  <div class="terms-and-conditions-container">
    <app-navbar4 rootClassName="navbar4-root-class-name6"></app-navbar4>
    <div class="terms-and-conditions-container1 thq-section-padding">
      <div class="terms-and-conditions-max-width thq-section-max-width">
        <h2
          id="termsConditions"
          class="terms-and-conditions-heading1 thq-heading-2"
        >Terms and Conditions
        </h2>
        <div class="terms-and-conditions-container2">

           <p>As an online platform that provides access to products and services that are considered as “passion purchases” and “collectibles” these could form part of a broader investment portfolio. WealthExpress Limited (t/a Yubaro) provides each client relationship with the following activities:</p>
			<ul>
				<li>Fact find & financial health checks for each product and service found on our platform;</li>
				<li>Guidelines surrounding the length of each passion purchase to maximise potential financial returns;</li>
				<li>Inclusion of additional supplementary services linked to the passion purchase or collectible i.e. insurance warehouse storage etc.;</li>
				<li>Independent market-driven data containing valuations and potential returns on each category of a passion purchase or collectible;</li>
				<li>Transparent pricing model for each product and service to ensure any purchases remain within the scope of your financial investment range;</li>
			</ul>
			<p>All these activities are stated in our Term of Business and the arrangement is that of a service provider and intermediary capacity only. Our carefully selected products have undergone significant due diligence checks prior to launch on our platform. These checks will remain in place as long as the product and vendor remain a preferred supplier for Yubaro.</p>
			<p>Yubaro is required to take appropriate steps to ensure we are aware of any facts about your financial position objectives and risk profile that we might reasonably need to know in order to assess the suitability of our products and services.</p>
			<p>By completing the application in its entirety you will enable us to fully understand your preferences and requirements. We will ask you to update this information from time to time but in the interim it is important that you notify us immediately of any material change in your circumstances as this may affect the suitability of any decision you make. This assessment does not represent a financial planning check.</p>
			
			<h3>Your Responsibilities</h3>
			<p>If you do accept and agree to the recommendations made then you automatically accept the risks of the relevant asset and the size of the transaction.</p>
			<p>Notify us of any changes in your personal and financial circumstances for us to provide you with suitable and appropriate advice regarding the products and services found on our passion purchase and collectible platform. You can notify us by using the ‘contact’ section on our app or emailing help@yubaro.io</p>

			<h3>Risk Warnings</h3>
			<p>Purchasing assets that can be considered as a collectible or a passion product can carry risk; the value and returns of assets may go down as well as up. You may not get back the money you paid. Past performance is not a reliable indicator of future results and purchasing any collectible asset can carry the risk that all or some of the capital might be lost. Please kindly consider your own personal financial circumstances before making a purchase.</p>

			<h3>Definitions</h3>
			<p>For the purposes of these Terms and Conditions the following terms shall have the meanings ascribed to them below:</p>
			<ul>
				<li><strong>Passion Purchase:</strong> Refers to any product or service listed on the Yubaro platform that is acquired for personal enjoyment or as a collectible investment. This includes but is not limited to fine art luxury items rare collectibles and other items of significant personal or aesthetic value.</li>
				<li><strong>Alternative Asset:</strong> A non-traditional asset class that differs from conventional investments such as stocks bonds or cash. Alternative assets include but are not limited to passion purchases collectibles real estate private equity hedge funds commodities and tangible assets like precious metals fine art whiskey casks and fine wines. These assets often require unique expertise for valuation and can provide diversification benefits to an investment portfolio.</li>
				<li><strong>Collectible:</strong> An item valued and sought after by collectors typically due to its rarity aesthetic value or personal significance. Collectibles can include items such as rare coins stamps fine art memorabilia and luxury goods.</li>
				<li><strong>WealthExpress Limited:</strong> The company operating the Yubaro platform providing access to passion purchases and collectibles as well as related services. WealthExpress Limited is registered in England and Wales trading as Yubaro.</li>
				<li><strong>Third-Party Affiliate:</strong> Any company or entity that Yubaro collaborates with to provide products services or supplementary offerings related to alternative asset purchases and collectibles. This includes but is not limited to insurance providers storage facilities and valuation experts.</li>
				<li><strong>Agent:</strong> A business or individual authorized to act on behalf of another person (the principal) in transactions involving passion purchases and collectibles. The agent has the authority to make decisions and enter into agreements on behalf of the principal.</li>
				<li><strong>Client Documents:</strong> All documents provided by Yubaro to its clients including these Terms and Conditions agreements policies notices and any other documentation that sets out the rights and obligations of the parties involved in transactions on the Yubaro platform.</li>
				<li><strong>Applicable Rules:</strong> All relevant laws regulations guidelines and standards that apply to the operations of Yubaro and the transactions conducted on its platform. This includes but is not limited to regulations under the Financial Services and Markets Act 2000 (FSMA) anti-money laundering (AML) laws and data protection laws such as the Data Protection Act 2018 (DPA) and the General Data Protection Regulation (GDPR).</li>
				<li><strong>Losses:</strong> Any and all losses damages costs expenses fines penalties or liabilities incurred by a party. This includes losses arising from third-party claims regulatory actions breaches of contract and other financial or reputational harm.</li>
				<li><strong>Indemnified Persons:</strong> Individuals or entities that are indemnified under these Terms and Conditions including Yubaro its associates third-party agents affiliates delegates directors officers and employees.</li>
				<li><strong>Principal:</strong> The person or entity on whose behalf an agent acts in transactions involving passion purchases and collectibles. The principal is ultimately responsible for fulfilling the obligations and liabilities arising from these transactions.</li>
				<li><strong>Confidential Information:</strong> Any non-public information shared between the parties under these Terms and Conditions which is meant to be kept confidential. This includes personal information financial details business strategies and other sensitive data.</li>
				<li><strong>Force Majeure:</strong> Events or circumstances beyond the reasonable control of a party that prevent or delay the performance of obligations under these Terms and Conditions. Examples include natural disasters acts of terrorism industrial disputes and governmental regulations.</li>
				<li><strong>Event of Default:</strong> Specific events or conditions that constitute a breach of these Terms and Conditions allowing the non-defaulting party to take certain actions such as termination of the agreement. Events of default include failure to pay amounts due insolvency and material adverse changes in financial condition.</li>
				<li><strong>Services:</strong> The range of activities provided by Yubaro to its clients including the execution of transactions provision of product information and advice and other related services as agreed upon between Yubaro and the client.</li>
				<li><strong>VAT:</strong> Value Added Tax a type of tax levied on the sale of goods and services. Any references to VAT in these Terms and Conditions pertain to the applicable tax regulations and obligations.</li>
			</ul>
			<p>These definitions shall apply throughout these Terms and Conditions and any related documents unless the context requires otherwise.</p>

			<h2>Terms of Business</h2>
			<p><b>1. Application and Scope of These Terms</b></p>
			<p>Acceptance of these Terms by you will be legally binding and they will apply on the basis set out below to any designated business or individual which relates to the purchase of passion and collectible products and services we provide to you as set out hereunder.</p>
			<p>These Terms supersede any terms of business that may have previously been sent to you. Transactions under these terms of business shall be deemed, with effect from your acceptance of these Terms, as transactions entered into under these Terms.</p>
			<p>These Terms are without prejudice to and shall not supersede or amend any other contract(s) entered into by you and us. Where there is inconsistency between these Terms and the contract(s), the contract(s) shall prevail.</p>
			<p>These Terms are always subject to applicable rules and any transactions entered into by you under these Terms are subject to the applicable rules. In the event of a conflict between these Terms and any such applicable rules, such applicable rules shall prevail. In no event shall we be obliged to take any action or refrain from taking any action which we believe would breach any applicable rules.</p>
			<p>We may from time to time issue you with an additional supplement or notice setting out additional provisions to these Terms which will apply in respect of effecting transactions in certain jurisdictions and/or services.</p>
			<p><b>2. Services to Be Provided Under These Terms and Conditions</b></p>
			<p>We may provide, at our sole discretion, the following services, unless otherwise specified:</p>
			<ol>
				<li>Arranging the execution of transactions upon your instructions in accordance with these Terms;</li>
				<li>Provision of product information and advice; and</li>
				<li>Other such services as may be agreed between us separately from time to time in writing, including but not limited to management services;</li>
			</ol>
			<p>We shall have no obligation to notify you of any corporate action. Additionally, we shall not be responsible for the following corporate actions unless you have specifically instructed us and we have agreed in writing to comply with such instruction, including but not limited to matters such as:</p>
			<ul>
				<li>Taking up any rights;</li>
				<li>Exercising conversion or subscription rights;</li>
				<li>Dealing with take-overs, other offers or capital reorganisations; and</li>
				<li>Exercising voting rights.</li>
			</ul>
			<p>We give no warranty, assurance, or undertaking as to the performance, returns, increase in or retention of value or profitability of your passion and collectibles purchase(s) (or any part of it or that any investment objectives or target as set shall be successfully achieved, whether in whole or in part). We do not endorse or provide any guarantees regarding the vendors that are on our platform, and you should undertake your own due diligence to verify any vendor that is introduced to you by Yubaro.</p>
			<p>We will not provide or be responsible for the provisions of any tax or legal advice in respect of your passion and collectibles purchase(s), and we give no advice, representation, warranty, or guarantee as to the tax consequences of any transaction.</p>
			<p>Based on information provided by you, we shall take reasonable steps to ensure that in providing the services, any recommendation to make a purchase is suitable for you and we shall have full authority to restrict such transactions as we consider necessary or desirable to achieve any instructions given by you or any authorised person on your behalf. We are entitled to assume that you have the necessary level of experience and knowledge in order to understand the risks involved, and financially bear any related risks associated with your passion and collectibles purchase or in the arrangement of your portfolio. You shall be responsible for ensuring that information provided to us is kept up to date to enable us to maintain accurate records of all purchases.</p>
			<p><b>3. Categorisation</b></p>
			<p>Our place of business is 70 Baker Street, London, W1U 7DL. If you are an investment manager acting as an agent on behalf of one or more purchasers, in relation to business conducted pursuant to these Terms, you shall not be liable as principal to perform any terms of any transaction under these Terms and the relevant principal or principals on whose behalf you are acting shall be liable in respect to all obligations and liabilities to be performed in respect of any transaction you have entered into on their behalf under these Terms, save as otherwise provided in these Terms and save whereby you conduct or otherwise you have held yourselves out as acting as principal in respect of a transaction under these Terms, in which case you shall be jointly and severally liable with the relevant principals in respect of all obligations and liabilities to be performed in respect of that transaction.</p>
			<p>If you are not an investment manager but are acting as an agent on behalf of one or more principals in relation to purchases conducted pursuant to these Terms, you and each of your principals shall be jointly and severally liable in respect of all obligations and liabilities to be performed in respect of any transactions you have entered into on the relevant principal’s behalf under these Terms, and references under these Terms to any principal on whose behalf you are acting as agent other than as an investment manager shall be construed as a reference to both you and your principal jointly and severally.</p>
			<p><b>4. Third-Party Affiliates</b></p>
			<p>We may provide services with or through our third-party affiliates or other entities or delegate the performance of services to any affiliate or other entity without your further consent and employ such agents on such terms as we deem appropriate. Without limiting our rights under these Terms, in respect of transactions with or through such third parties, you may be subject to any business terms and conditions of such persons.</p>
			<p>We may recommend the services of, or pass an order to, any third-party affiliate. We may introduce you to a third-party affiliate outside the United Kingdom which may not be regulated or be registered as an authorised person subject to regulation under the terms of the Financial Services and Markets Act 2000 (“FSMA”), and any money held by such an affiliate on you (or, where applicable, your principal’s or principals’) behalf may be treated differently to how it would be treated if it were held by an authorised person who is subject to regulation under the terms of the FSMA.</p>
			<p><b>5. Provision of Financial and Other Information</b></p>
			<p>You will provide us with financial and other information concerning yourself (and/or) where you are acting as an agent for any principal or principals, information on that other party or parties (as we from time to time may reasonably request or as we may be required to procure as a matter of law or regulations), and you will notify us immediately of any material adverse change in your financial status (and/or where you are acting as an agent for a principal or principals, the financial status of each principal).</p>
			<p>You also authorise us to disclose information to your investment manager, investment adviser, auditors, administrators, other advisors or agents as they may from time to time request, and to disclose such information to third parties (including but not limited to investors) at the direction of your investment manager, investment adviser, administrator and other advisers or agents. We shall not be liable to you for any losses (as defined below) of any kind or nature whatsoever, howsoever caused, resulting from any such disclosure following the request of your investment manager, investment adviser, administrators, other advisors or agents.</p>
			<p><b>6. Charges</b></p>
			<p>We may charge you a commission on transactions effected for you as an agent. Our commission will be in accordance with our notified rates or may be agreed between us. Commission will always be identified and notified to you separately and, if applicable, be displayed on the listing page.</p>
			<p>We may charge you a mark-up or mark-down at our discretion on transactions effected with you as principal. Any difference in price from that at which best execution could be achieved will be our charges and will be shown as such in the confirmation.</p>
			<p>In addition to commission and charges, you will be responsible for paying for all third-party costs and expenses relating to transactions effected for or with you including stamp duty, transaction charges, levies, fees, and custodial and similar expenses.</p>
			<p>We or an associate may receive remuneration in connection with transactions performed by them for you in addition to any commission or mark-up or mark-down receivable by us described above. We or an associate may share any commission or mark-up or mark-down with an associate or third party.</p>
			<p>You must pay our charges, fees, and expenses and reimburse us for any losses we incur (unless they arise directly from our own negligence, wilful default or fraud) on demand without deduction, counterclaim, or set-off. If you do not pay them when due, we may deduct equivalent amounts from any purchase gain you maintain with our third-party affiliate.</p>
			<p>We may estimate and deduct any applicable taxes from payments due to you. Where VAT is due on our charges (including any charges, fees, and expenses) payable by you to us, you shall be responsible for such VAT and shall pay such VAT to the relevant tax authorities or to us as required by any applicable rules.</p>
			<p><b>7. Orders, Instructions, and Confirmations</b></p>
			<p>Orders or instructions may be given by you through the Yubaro app/website and shall be transmitted at your risk as specified by us or agreed upon from time to time. You agree that we shall not be liable for any loss, damage, cost, or expense suffered by you in respect of any communication which has not been accepted, received, or acted upon by us, particularly where the communication is not addressed to a named individual or lacks identification of the responsible person.</p>
			<p>You authorize us to act on and treat as binding any communication through the Yubaro app/website reasonably accepted by us in good faith as given by you or on your behalf by an authorized person. You are responsible for all contracts, obligations, costs, and expenses reasonably incurred by us on your behalf in connection with such communications. You may notify us in writing of any changes to the authorized persons and provide evidence of such authority. Until we receive such notice, we assume authorized persons have full power to give instructions on your behalf.</p>
			<p>You confirm that you have no objection to our use of voice recording procedures for inquiries. Our voice records shall be our sole property and will be accepted as conclusive evidence of the recorded communications. The retention period for voice records is at our discretion.</p>
			<p>We screen all leads to ensure they meet our standards and the requirements of our third-party affiliates. We reserve the right to withhold passing any lead if we believe it may not be accepted by our affiliates. This screening process is designed to maintain the integrity and quality of the leads we provide.</p>
			<p>We are not obligated to act on any instruction if there are insufficient details or if compliance might result in contravening any law or regulation. If we pass on any leads and the relevant details are not provided as due, you will fully indemnify us and our affiliates against all related liabilities, losses, and expenses, including legal fees.</p>
			<p>All contracts and communications will be sent to your email or mailing address on record and are binding unless you object in writing within five (5) business days. Proof of correct addressing or transmission will suffice to establish delivery. Confirmations are binding unless objected to within the specified period.</p>
			<p><b>8. Conflicts of Interest</b></p>
			<p>Your attention is drawn to the fact that if and when we provide any services on your behalf, we, or some other person connected with us including an associate or an employee may have an interest, relationship, or arrangement that is material in relation to the purchase of the collectible asset, transaction, or service concerned.</p>
			<p>Whilst Yubaro is not regulated by the FCA Rules, we will take all appropriate steps to identify and to prevent or manage conflicts of interest including those caused by the receipt or inducements from third parties or by our own remuneration and other incentive structures. Our conflicts of interest policy detailing how we will prevent or manage conflicts of interest is available upon request.  If you would like to report a conflict of interest, please contact us at <a href="mailto:complaints@yubaro.io">complaints@yubaro.io</a> or via mail at:</p>
			<p>Compliance Officer, <br/>WealthExpress Limited, <br/>70 Baker Street, <br/>London, <br/>W1U 7DL<br/>United Kingdom</p>
			<p><b>9. Confidentiality</b></p>
			<p><b>9.1 Confidential Information</b></p>
			<p>Neither party to these Terms will disclose to any person without the other’s prior written consent any non-public information (including personal information) which is imparted in confidence under these Terms (“Confidential Information”). Confidential Information includes, but is not limited to:</p>
			<ul>
				<li>Personal information provided by clients</li>
				<li>Financial details and statements</li>
				<li>Business strategies and plans</li>
				<li>Proprietary data, including valuations and market analysis</li>
				<li>Any other information marked or otherwise identified as confidential</li>
			</ul>
			<p><b>9.2 Obligations of Confidentiality</b></p>
			<p>Each party to these Terms shall:</p>
			<ul>
				<li>Treat Confidential Information as strictly confidential and not disclose it to any third party without the prior written consent of the disclosing party.</li>
				<li>Use Confidential Information solely for the purpose of performing obligations or exercising rights under these Terms.</li>
				<li>Take all reasonable measures to protect the confidentiality of the Confidential Information, including implementing appropriate technical and organizational measures to prevent unauthorized access, disclosure, alteration, or destruction.</li>
			</ul>
			<p><b>9.3 Permitted Disclosures</b></p>
			<p>Confidential Information may be disclosed without prior written consent in the following circumstances:</p>
			<ol>
				<li><b>Public Domain</b>: Information which is already in, or comes into, the public domain otherwise than through the recipient’s unauthorized disclosure (except that any compilation of otherwise public information in a form not publicly known shall nevertheless be treated as Confidential Information).</li>
				<li><b>Legal Requirement</b>: If required to do so under applicable laws, regulations, or legal processes.</li>
				<li><b>Regulatory Requests</b>: If requested by regulatory, self-regulatory, or fiscal authorities or a court or tribunal of competent jurisdiction.</li>
				<li><b>Professional Advisors</b>: Disclosure in confidence to advisers, auditors, or insurers where reasonably necessary for the performance of their professional services.</li>
			</ol>
			<p><b>9.4 Duration of Confidentiality Obligations</b></p>
			<p>The confidentiality obligations set out in this section shall continue to apply even after the termination of these Terms for as long as the Confidential Information remains confidential.</p>
			<p><b>9.5 Remedies for Breach</b></p>
			<p>The parties to these Terms agree that damages may not be an adequate remedy for any breach of this section. Accordingly, each party shall be entitled, without proving special damages, to seek injunctive or other equitable relief restraining the other party from breaching this section.</p>
			<p><b>9.6 Return or Destruction of Confidential Information</b></p>
			<p>Upon termination of these Terms or upon request by the disclosing party, the receiving party shall promptly return or destroy all Confidential Information, including any copies, reproductions, or extracts thereof, and provide written confirmation of such destruction or return.</p>
			<p><b>9.7 Requests from Regulators</b></p>
			<p>If any regulator asks us for information relating to you or any person responsible for or benefitting from any transactions, you must promptly provide it to us. In providing our services under these Terms, neither we nor our third-party affiliates shall be obliged to disclose or to take into consideration (or to require any third party to disclose or take into consideration) any information:</p>
			<ol>
				<li><b>Breach of Law</b>: The disclosure or use of which might breach any prohibition, duty, or confidence to any other person or arising under any applicable law.</li>
				<li><b>Internal Awareness</b>: Which comes to the notice of an employee, officer, or agent of us or of an affiliate, but properly does not come to the actual notice of an individual executing orders.</li>
				<li><b>Financial Interests</b>: Relating to the nature or extent of any interest we or any affiliate has in any financial instruments.</li>
				<li><b>Legal Privilege</b>: To the extent that it is subject to legal privilege.</li>
			</ol>
			<p>These confidentiality provisions are designed to protect the integrity of the information exchanged between the parties and ensure that both parties uphold the highest standards of confidentiality and data protection.</p>
			<p><b>10. Amendments and Termination</b></p>
			<p>We reserve the right to amend these Terms at any time on notice by issuing to you further Terms with effect from the earlier of </p>
			<ol type="i">
				<li>receipt of instructions by you to provide you with services as contemplated under the new Terms or </li>
				<li>ten (10) business days from the date of the provision of the new Terms to you.  We will notify you of any changes to the terms and conditions via your registered email address.</li>
			</ol>
			<p>You and we may terminate these arrangements by giving us notice of termination with effect of ten (10) business days from its receipt. You may terminate these arrangements by contacting us via the app or emailing <a href="mailto:help@yubaro.io">help@yubaro.io</a>. Any such amendment or termination will not affect accrued rights, or any commitment already entered into by you or by us on your behalf or any provision of the client documents intended to survive termination. Any amendment will apply in respect of any commitment or transaction entered into by us after the expiry of ten (10) business days from the date on which notice of the amendment is sent to you. On termination, we may return and/or realise such assets as may be required to settle purchase orders already initiated and to pay any outstanding liabilities of you.</p>
			<p><b>11. Events of Default</b></p>
			<p>In the Event of Default (as defined below) we may:</p>
			<ol>
				<li>Terminate these Terms with immediate effect upon written notice to you, whereupon any amount or other obligations owed by you to us and/or any third-party affiliate under these Terms shall become immediately due and payable and due for performance.</li>
				<li>Terminate with immediate effect any outstanding purchase orders entered into between us and you under these Terms as we shall determine and notify you of such termination as soon as reasonably practicable, whereupon any amount or other obligations owed by you to us and/or any third-party affiliate in respect of those transactions all become immediately due and payable and due for performance but these Terms will remain in force between us in respect of all other purchases, and/or</li>
				<li>Without prejudice to any of our other rights, exercise any of our powers pursuant to Paragraphs 21, 22 and 10.</li>
			</ol>
			<p>The Events of Default are defined as follows:</p>
			<ol>
				<li>You fail to pay any amount due and owing or fail to deliver when due any property in respect of any purchase order with us or any third-party affiliate;</li>
				<li>You otherwise default in the due performance and observance of any other provision of these Terms.</li>
				<li>Any representation or warranty made by you being incorrect, untrue, or ceasing to be true in any material respect at any time, or you fail to comply with any undertaking made by you under these Terms;</li>
				<li>Any material adverse change in your financial condition which, in our opinion, may jeopardise our position in relation to any purchase order entered into with you;</li>
				<li>You become insolvent or unable to pay your debts as they become due, or make a general assignment, arrangement or composition with or for the benefit of creditors, or become the subject of insolvency, bankruptcy or similar proceedings or a petition is presented for your winding up or liquidation or a trustee, receiver or manager is appointed overall or substantially all of your assets;</li>
				<li>Where you are acting as a trustee, you cease to act as trustee of the relevant trust, or you lose your trustee indemnity.</li>
				<li>
					Where you are a natural person and you are or become of unsound mind, we may:
					<ul>
						<li>Terminate these Terms, with immediate effect upon written notice to you, whereupon any amount or other obligations owed by you to us and/or any affiliate under these Terms shall become immediately due and payable and due for performance;</li>
						<li>Terminate with immediate effect any outstanding transactions entered into between us and you under these Terms as we shall determine and notify you of such termination as soon as reasonably practicable, whereupon any amount or other obligations owed by you to us and/or any affiliate in respect of those transactions shall become immediately due and payable and due for performance but these Terms will remain in force between you and us in respect of all other transactions; and</li>
						<li>Without prejudice to any of our other rights, exercise any of our powers pursuant to Paragraphs 7 and 14.</li>
					</ul>
				</li>
			</ol>
			<p>If an Event of Default occurs in relation to you, you shall immediately give written notice to us, specifying the relevant Event of Default. Neither the existence nor non-existence of such notification by you shall prejudice the rights and remedies available to us under these Terms or any applicable rules.</p>
			<p>Where in relation to any purchase order carried out pursuant to these Terms you are acting as an agent for any principal or principals, any Event of Default in relation to you shall constitute an Event of Default in respect of that principal or principals on whose behalf you are acting as agent, unless otherwise determined by us.</p>
			<p>Where an Event of Default occurs in relation to a principal or principals on whose behalf you are acting as agent, these Terms can be terminated by us in relation to such principal(s) under this paragraph without affecting the continuation of these Terms in relation to you and any other party on whose behalf you act.</p>
			<p>It shall also be an Event of Default if any of the Event of Default events occur in relation to a guarantor, unless otherwise determined by us.</p>
			<p><b>12. No Reliance or Advice</b></p>
			<p>In the provision of our services to you, neither we nor any of our third-party affiliates shall owe you any duty to advise on the merits or suitability of any purchases or series of purchases or those contemplated by you, unless specifically agreed otherwise in writing.</p>
			<p>Without limitation to the generalities of the foregoing, we shall not give you legal, regulatory, account, taxation, financial, or any other advice in relation to any purchase or service of purchase and you are solely responsible for seeking and obtaining your own advice and taking your own purchase decisions. You agree that you will rely on your own judgement for all purchase decisions or series of purchase decisions and that you are not in any way acting in reliance on us.</p>
			<p>Furthermore, any research, recommendations, ideas, information about a purchase and purchase strategy, product commentary, generic advisory material or other information communicated to you is not personalised to, tailored to, or based on a consideration of your individual circumstance is incidental to the provision of services by us under these Terms and should not be relied upon. Neither we nor any of our third-party affiliates give any representation, warranty, or guarantee as to the accuracy and completeness of any such information or as to the regulatory, legal, account, taxation, or other consequences of any purchase.</p>
			<p><b>13. Prevention of Money Laundering</b></p>
			<p>We have certain responsibilities under the anti-money laundering and financial terrorism laws to verify the identity and address of clients and their source of funds. We will need to make inquiries and obtain information from you for this purpose.</p>
			<p>You confirm that all information you supply will be accurate and you agree that we may make inquiries of any person or authority to establish or check certain facts. These laws and regulations require us to deter money launderers from using us as a conduit for their illegal activities, to identify and report suspicious transactions, and to keep an audit trail for use in any subsequent investigation into money laundering activities. Our obligations under these laws and regulations override any obligations of confidentiality which may otherwise be owed to you. We may be obliged to notify the relevant authorities in the UK or other jurisdictions of any transactions which we may suspect involve the laundering of the proceeds of crime or involve the financing of, any criminal activity, regardless of where that crime may have been committed. We shall therefore deal with you on the understanding that you are complying with and will continue to apply all applicable anti-money laundering legislation to which you may be subject. We may also from time to time seek your written assurance that you have records evidencing that you have identified your clients in accordance with applicable anti-money laundering legislation, as applicable, if at such time you are unable to provide us with such assurance, we reserve the right to cease to deal with you without limiting any other rights under these Terms.</p>
			<p><b>14. Risk Warning</b></p>
			<p>This notice is provided to you in compliance with the applicable rules. Please be aware that there are certain risks involved in entering into the purchasing of passion assets.</p>
			<p>You should not deal in these passion assets unless you understand their nature and the extent of exposure to risk that you will incur.</p>
			<p>The information contained in any product specific Risk Notice present on our offer pages cannot disclose the nature of all risks of all passion assets or services or disclose everything about generic types of risk. You should not rely on the highlighted risks as being the only risks in relation to the assets or service. You should always satisfy yourselves that a passion product and collectible purchase is suitable for you in light of your personal and financial circumstances, risk appetite and that you fully understand the nature and risk associated with the asset or service. Any risks highlighted are not to be relied upon as purchase advice or a personal recommendation. You should seek independent financial advice if needed.</p>
			<p><b>15. Representations and Warranties</b></p>
			<p>You represent, warrant and undertake on an ongoing basis that:</p>
			<ol>
				<li>You are duly organised under the laws of your jurisdiction or incorporation and/or residency;</li>
				<li>You have all necessary power of authority to execute, deliver and perform your obligations under these Terms and to enter into the purchase of passion and collectible assets by these Terms;</li>
				<li>You have all necessary power and authority to confer on us such authorities as are necessary to enter into the purchase of passion and collectible assets contemplated by these Terms;</li>
				<li>These Terms and any service or purchase contemplated or conducted or executed by you or for you with the third-party affiliate will constitute your legal, valid and binding obligations, enforceable against you in accordance with the provisions of these Terms, subject only to applicable bankruptcy, insolvency, reorganisation moratorium or other similar laws affecting creditors rights;</li>
				<li>Neither your entry into these Terms nor into any transaction contemplated by these Terms will breach any applicable rules;</li>
				<li>All necessary consents and authorities to enable you to conduct all transactions and contract to receive all services under these Terms have been obtained and will be maintained by you;</li>
				<li>You have obtained and are in compliance with the Terms of all authorisations, consents and approvals or any government or other regulatory body necessary to enable you to conduct all purchases under these Terms, and you shall provide us with copies of such consents or approvals as we may reasonably require;</li>
				<li>You will act in good faith, observing a standard of behaviour reasonably expected of persons in your position;</li>
				<li>Unless expressly entitled to exercise an absolute discretion, you will act reasonably. (and will be presumed to have done so);</li>
				<li>Any of your purchases which we or our third-party affiliate complete on your behalf pursuant to these Terms are or will be beneficially owned by you free from all items, charges, incumbents other than those which may arise in our favour, or in the case of acting in the capacity of a trustee or custodian. You represent that you have obtained a representation of beneficial ownership, free from all liens, charges and incumbents from the beneficial owners and that the beneficial owner has authorised you to deal with such purchases.</li>
				<li>You are and will be knowledgeable of and experienced in the risk of entering into purchases under these Terms, capable of evaluating the merits and risks of such purchase orders and able to bear the economic risks of such.</li>
				<li>You shall promptly provide, or procure to be provided to us, all information or documents, and complete, sign or execute any documents, that are reasonably necessary for us to receive with a view to the proper discharge of the Services under these Terms or which we may reasonably request for such purchase or which is required by any competent authority.</li>
				<li>Information or documentation provided by you to us pursuant to these Terms is accurate, complete, up to date and not misleading in any respect and you have notified us of all such information which is reasonably relevant to the performance of the services under these Terms;</li>
				<li>Each payment by you shall be made without any deduction or withholding on account of tax, save where such deduction or withholding is required by law. In which case the amount of payment due shall be increased to an amount which (after making any deduction or withholding) leaves an amount equal to the payment which would have been due if no deduction or withholding had been required.</li>
				<li>You will comply with and fulfil all of your obligations under applicable rules and will not breach any applicable rules in respect of entering into or performing any purchase under these Terms and you will provide us with any instructions or others and/or complete such procedural formalities as may be required by applicable tax or other law and/or practice and at the request of us. You will supply in a timely manner all tax-related forms, documents, certificates or other information that may be periodically required to enable us to comply with our or any other tax-related information reporting obligations and/or make any payments to you without reduction of any tax withholding or at a reduced rate of withholding.</li>
			</ol>
			<p>If in relation to any purchase performed by you with the agent pursuant to these Terms, if you are acting as an agent for any principal or principals then, on a continuing basis (on behalf of yourself and any principal as applicable), you additionally represent, warrant and undertake to us that:</p>
			<ol>
				<li>You have full power, authority and capacity from your principal or each of your principals to enter into and perform your obligations under and pursuant to these Terms including, without limitation, entering into transactions under these Terms on your principal’s or principals’ behalf.</li>
				<li>In so doing, you are expressly authorised by your principal or each of your principals to instruct us in relation to any such purchase and each purchase is entered into on the relevant principal or principals’ behalf and the relevant principal or principals shall be liable in respect of all obligations and liabilities to be performed in respect of any such purchase.</li>
				<li>Where you are acting as an agent on behalf of one or more principals in relation to business conducted pursuant to these Terms, you and the relevant principal will be jointly and severally liable to us in respect of all obligations and liabilities to be performed in respect of any such purchase;</li>
				<li>You have carried out all due diligence required under relevant laws, including without limitation all applicable prevention and detection of money laundering, client identification, sanctions (for the avoidance of doubt), including any prevention and detection of terrorism legislation), laws and regulations to satisfy yourselves of the good standing of your principal or each of your principals and that your principal or each of your principals is not involved in any money laundering or criminal activity.</li>
				<li>You assume full responsibility for, and shall ensure compliance with, without limitation any and all suitability, supervision control, registration, credit review, rules and regulations and other requirements and restrictions of any applicable rules in respect of your principal or each principal’s use of services under these Terms;</li>
				<li>You will use all reasonable endeavours to ensure that any principal or principals on whose behalf you act as agent complies with and fulfils all of its obligations under any purchases entered into pursuant to these Terms;</li>
				<li>You hold and will at all times hold all requisite authorities from your principal or each principal to grant the interests in respect of the purchase, monies and other property of such principal created by Paragraph 6 (Charges) and</li>
				<li>Each of your principals are able to and hereby does, make the representations in this Paragraph as if all references to you in this Paragraph are references to each of your principals, and you have carried out the requisite due diligence to satisfy yourselves of this.</li>
			</ol>
			<p>You will notify us, if any of the representations, warranties and undertakings contained in this Paragraph ceases to be true or correct.</p>
			<p>We represent, warrant and agree, on the date of these Terms that we are:</p>
			<ol>
				<li>Duly organised under the laws of England and Wales; and</li>
				<li>Have all the necessary power and authority to execute, deliver and perform the services under these Terms.</li>
			</ol>
			<p><b>16. Covenants</b></p>
			<p>Save as otherwise provided in this Paragraph, you covenant to us from the date of these Terms and on a continuing basis therefore that you will:</p>
			<ol>
				<li>At all times obtain, comply with and maintain in full force and effect all licenses and permissions required to comply with any applicable rules;</li>
				<li>Promptly notify us if you breach these Terms;</li>
				<li>Comply with all applicable rules as they apply to you, and use all reasonable efforts to enable us to comply with all applicable rules as they apply to us;</li>
				<li>Promptly notify us of any alteration to the identity of any authorised persons;</li>
				<li>Promptly provide such information regarding your financial affairs as we may request;</li>
				<li>Observe the standard of behaviour expected of a prudent person in your position and do nothing which may prevent us from observing that standard; and</li>
			</ol>
			<p>Where you act as a disclosed agent on behalf of a principal, you make the covenants set out in this Paragraph on your own behalf as an agent and on behalf of each principal.</p>
			<p>You will notify us if you cease to act for any principal or if the terms of your appointment are changed in a way which would affect these Terms.</p>
			<p><b>17. Recordings and Record Keeping</b></p>
			<p>We will take all reasonable steps to secure while you consent to us recording telephone conversations and keeping a copy of electronic communications.</p>
			<p>Records in relation to telephone and electronic communications and in relation to any purchase orders will be kept by us for a period of five (5) years.</p>
			<p>Record keeping of the documents agreed between us and you which set out the rights and obligations of the parties under an agreement to provide services or the terms on which we provide services shall be maintained by us for at least the duration of the relationship with you.</p>
			<p><b>18. Complaints</b></p>
			<p>All formal complaints should be made in writing to the Head of Risk Management at the address – WealthExpress Limited, 70 Baker Street, London, W1U 7DL or by email to complaints@yubaro.io. We aim to respond to all complaints within twenty eight (28) calendar days. A copy of our complaints handling procedure is available on request. Any proposed solution shall not be deemed a compromise by either party to these Terms or its respective rights under the Terms or generally at law. As Yubaro is an unregulated legal entity, any complaints raised with the Financial Ombudsman Scheme are not deemed to be reportable.</p>
			<p><b>19. Correspondence and Papers</b></p>
			<p>All correspondence and other papers held by us in relation to any matter undertaken for you shall be our sole property with the exception of:</p>
			<ol>
				<li>Original contracts and other documents of title held to your order; and</li>
				<li>Any documents the return of which you have requested on or before sending them to us.</li>
			</ol>
			<p><b>20. Contracts (Rights of Third Parties) Act</b></p>
			<p>A person who is not a party to these Terms (other than an indemnified person, successor in title or permitted assignee) has no rights under the Contracts (Rights of Third Parties Act 1999) to enforce any of these terms but this does not affect any right or remedy of such third party which exists or is available apart from under that Act.</p>
			<p><b>21. Data Protection</b></p>
			<p>All personal data about you and provided by you under the Data Protection Act 2018 (“DPA”) and Regulation EU 2016/679 (“GDPR”) and we will be considered the data controller for this purpose as defined under DPA and GDPR. For this purpose, you acknowledge and agree that the Data Controller may pass your personal data to, among others, any regulatory authority by which the Data Controller is regulated and in accordance with any legal requirement which applies to the Data Controller.</p>
			<p>Under GDPR we need to inform you that we collect and use personal data on the basis that it is in its legitimate interests to do so and your right to privacy is not overridden.</p>
			<p>We collect information via our Yubaro App and throughout our relationship with you in order to comply with regulation and for the purposes of managing your profile(s) and performing our services to you. The provision of your personal data is required in order to enter into a contract with us, however, the provision of information for marketing purposes is voluntary. We may record and monitor telephone calls made to or by us in order to comply with regulatory obligations. Automated decision making may be used in order to comply with our KYC/AML checks or to evidence that Yubaro is providing a suitable and appropriate service to its clients.</p>
			<p>More detailed information on how and why we use your information, including the rights in relation to your personal data, and our legal grounds for using it, please read our “Privacy Notice” for further clarifications.</p>
			<p><b>22. Force Majeure</b></p>
			<p>In the event of any failure, interruption or delay in performance of our obligations resulting from any acts, events or circumstances not reasonably within our control, including but not limited to industrial disputes, acts or regulations of governmental or supranational bodies or authorities, breakdown, failure or malfunction of any telecommunications or computer services, postal or other strikes, lock-outs or other industrial disputes (whether involving the workforce of the party so prevented or of any other party), act of terrorism or of God, fire, flood, storm, war, riot, civil commotion, malicious damage, default or suppliers or sub-contractors; neither party to these Terms shall be liable or have any responsibility of any kind for any losses incurred or suffered by the other party.</p>
			<p><b>23. Assignment</b></p>
			<p>Save as provided in this Paragraph, neither party to these Terms may assign, transfer or novate or purport to assign, transfer or novate any of its rights or obligations under these Terms without the prior written consent of the other party to these Terms, such request not to be unreasonably withheld or delayed.<br/>You agree that we may assign, transfer or novate our rights or obligations under these Terms to one or more of our affiliates by giving you notice which shall specify a date upon which the assignment has or shall become effective.</p>
			<p><b>24. Waivers</b></p>
			<p>No failure on the part of either party to these Terms to exercise, nor delay by it in exercising, any right or remedy under these Terms or by law shall operate as a waiver thereof, or of any other right or remedy, nor shall any single or partial exercise of any right or remedy preclude any other further exercise of that right or remedy or the exercise of any other right or remedy. The rights and remedies provided in these Terms are cumulative and not exclusive of any rights or remedies provided by law.</p>
			<p><b>25. Severability</b></p>
			<p>If any clause or part of these Terms is found by any court, tribunal, administrative body or authority of competent jurisdiction to be illegal, invalid or unenforceable then that provision shall, to the extent required, be severed from these Terms and shall be ineffective without, as far as is possible, modifying any other clause or part of these Terms and this shall not affect any other provision of these Terms, which shall remain in full force and effect.</p>
			<p><b>26. Limitation of Liability</b></p>
			<p>We shall not be liable for your losses in connection with these Terms, unless they arise as a result of default, fraud or negligence. Nothing in these Terms will exclude or restrict any liability for fraud or any duty or liability we may have to you. We shall not otherwise be liable for any other losses suffered by you including without limitation losses arising from:</p>
			<ol>
				<li>Carrying out or relying on any instructions and/or on any information provided or made available to us by you or any authorised person;</li>
				<li>Any delays due to market conditions or changes in market;</li>
				<li>Any loss or opportunity whereby the value of the passion and collectible purchase might have been increased;</li>
				<li>Any decline in the value of the purchase howsoever actioned;</li>
				<li>Any delayed receipt, non-receipt loss or corruption of any information contained in email or for any breach of confidentiality resulting from email communication;</li>
				<li>Any representation made by you being untrue and/or breach of warranty by you;</li>
				<li>Losses which you could have mitigated or reduced or avoided such losses; and</li>
				<li>Losses arising from our compliance with any applicable rules applicable laws, regulatory rules and rules.</li>
			</ol>
			<p>We shall not be liable in any circumstances for any losses that constitute indirect, special or consequential losses, punitive damages or loss of profits, loss of savings and pure economic loss, information, opportunity, goodwill or reputation in connection with or arising out of these Terms, even if we have been advised of the possibility of such losses.</p>
			<p>You agree to indemnify us, our associates, third-party agents and affiliates and delegates and the directors, officers and employees (“Indemnified Persons”) against all losses paid, suffered, incurred or made against the indemnified persons directly or indirectly arising as a result of:</p>
			<ol>
				<li>Any service performed by the indemnified persons under these;</li>
				<li>Carrying out or relying on any instructions and any information provided or made available to the indemnified persons by you or any authorised persons;</li>
				<li>Any party claiming to be entitled to your purchase orders;</li>
				<li>As a result of any representation made by you being untrue and/or for breach or warranty by you;</li>
				<li>The acts and omissions, or any claims made by your administrator, investment manager or any other third party except to the extent that such losses result directly from the negligence, default or fraud of the indemnified persons in providing the services under these Terms.</li>
			</ol>
			<p>Any indemnity given to the indemnified persons under these Terms is in addition to, and without prejudice to, any indemnity allowed to the indemnified persons under applicable law.</p>
			<p><b>27. Entire Agreement</b></p>
			<p>These Terms and any further client documentation entered into between us which make reference to these Terms and/or are referred to in these Terms, represent the entire terms on which we will undertake any purchase order with you and no alteration will have effect unless issued to you by us in writing. No person has been authorised to give any representation on our behalf as regards the client documents or our terms of business and any given must not be relied upon.</p>
			<p><b>28. Interpretation</b></p>
			<p>References to an “Associate” are to WealthExpress Limited and include any company which is owned directly or indirectly by it and to “client documents” are to these terms and conditions of business as from time to time supplemented, varied or replaced by any further client documentation whether or not the same are incorporated into or constitute a client agreement. “Further client documentation” means any further documents sent by us to you or by you to and approved by us, supplementing, varying or replacing any of the terms and conditions on which we transact investment business for you. “Third-Party Affiliate” refers to the company who is ultimately providing the passion and collectible asset and not WealthExpress Limited. “Agent” could be a business or individual who has authority to act on behalf of a person who is ultimately purchasing the passion and collectible asset.</p>
			<p><b>29. Governing Law</b></p>
			<p>These Terms will be construed and governed in all respects by English law, and the English Courts are to have exclusive jurisdiction to settle any disputes, controversy, suit, action, proceedings or claim of whatever nature arising out of or in any way in connection with these Terms, its subject matter or its formation (including non-contractual disputes or claims) (“Proceedings”) which may arise. Each party to these Terms irrevocably submits to the exclusive jurisdiction of the English courts in respect of such proceedings and waives any objection to any such proceedings in such courts on the grounds of venue, waives any claim that proceedings brought in such courts have been brought in an inappropriate or inconvenient forum and further waives the right to object, with respect to such proceedings, that such courts do not have any jurisdiction over such party to these Terms.</p>
			<p>Last Updated: 5<sup>th</sup> June 2024</p>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
    <cookie-consent-banner
      rootClassName="cookie-consent-banner-root-class-name7"
    ></cookie-consent-banner>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AppFooter from '../components/footer'
import CookieConsentBanner from '../components/cookie-consent-banner'

export default {
  name: 'TermsAndConditions',
  props: {},
  components: {
    AppNavbar4,
    AppFooter,
    CookieConsentBanner,
  },
  data() {
    return {
      rawjg3y: ' ',
      raw0vc2: ' ',
      rawk9i6: ' ',
      rawx09t: ' ',
      rawo3a3: ' ',
      rawt3p1: ' ',
      rawwn9s: ' ',
      raw3ljr: ' ',
    }
  },
  metaInfo: {
    title: 'Terms and Conditions - Yubaro',
    meta: [
      {
        name: 'description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        property: 'og:title',
        content: 'Terms and Conditions - Yubaro',
      },
      {
        property: 'og:description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
    ],
  },
}
</script>

<style scoped>
.terms-and-conditions-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #e0d5bb;
}
.terms-and-conditions-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.terms-and-conditions-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.terms-and-conditions-heading1 {
  align-self: flex-start;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.terms-and-conditions-container2 {
  width: 100%;
  font-family: "Poppins";
    line-height: 1.3;
}
.terms-and-conditions-container2 p,
.terms-and-conditions-container2 h2,
.terms-and-conditions-container2 h3{
  font-family: "Poppins";
  margin: 10px 0;
  line-height: 1.3;
}

.terms-and-conditions-container2 ul, 
.terms-and-conditions-container2 ol {
	padding-inline-start: 40px;
	}

.terms-and-conditions-container2 ol li,
.terms-and-conditions-container2 ul li{
	margin-bottom: 3px;
	}
	

</style>
