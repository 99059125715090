<template>
  <div class="page1-container">
    <app-navbar4 rootClassName="navbar4-root-class-name13"></app-navbar4>
    <div class="page1-header76">
      <div class="page1-max-width thq-section-max-width">
        <div class="page1-column thq-section-padding">
          <img alt="image" src="/yubaro-slash-200w.webp" class="page1-image" />
          <h1 id="aboutH" class="page1-text thq-heading-1">
            <span>Sorry, something went wrong</span>
            <br />
          </h1>
          <p class="page1-text3 thq-body-large">
            <span>
              It appears something went wrong with your request. Please try to
              register again.
            </span>
            <br />
          </p>
        </div>
        <about-image-section></about-image-section>
      </div>
    </div>
    <contact-us-bottom></contact-us-bottom>
    <app-footer></app-footer>
    <cookie-consent-banner
      rootClassName="cookie-consent-banner-root-class-name9"
    ></cookie-consent-banner>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AboutImageSection from '../components/about-image-section'
import ContactUsBottom from '../components/contact-us-bottom'
import AppFooter from '../components/footer'
import CookieConsentBanner from '../components/cookie-consent-banner'

export default {
  name: 'Page1',
  props: {},
  components: {
    AppNavbar4,
    AboutImageSection,
    ContactUsBottom,
    AppFooter,
    CookieConsentBanner,
  },
  metaInfo: {
    title: 'Error 500 - Yubaro',
    meta: [
      {
        name: 'description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        property: 'og:title',
        content: 'Error 500 - Yubaro',
      },
      {
        property: 'og:description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  },
}
</script>

<style scoped>
.page1-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #e0d5bb;
}
.page1-header76 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
}
.page1-max-width {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.page1-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.page1-image {
  top: 48px;
  left: 30px;
  width: 95px;
  height: 97px;
  z-index: 0;
  position: absolute;
  object-fit: cover;
}
.page1-text {
  z-index: 100;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 1.2;
}
.page1-text3 {
  font-family: "Poppins";
}
@media(max-width: 1600px) {
  .page1-text {
    line-height: 1.1;
  }
  .page1-text3 {
    font-family: Poppins;
  }
}
@media(max-width: 991px) {
  .page1-header76 {
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .page1-max-width {
    flex-direction: column;
  }
  .page1-column {
    width: 100%;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
}
@media(max-width: 767px) {
  .page1-column {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .page1-image {
    top: 28px;
    left: 15px;
    width: 61px;
    height: 62px;
  }
  .page1-text {
    width: 100%;
    font-style: normal;
    text-align: left;
    font-family: Poppins;
    font-weight: 500;
    line-height: 0.8;
  }
  .page1-text3 {
    text-align: left;
  }
}
@media(max-width: 479px) {
  .page1-column {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .page1-image {
    top: 9px;
    left: 2px;
    width: 49px;
    height: 49px;
  }
  .page1-text {
    font-size: 36px;
    text-align: left;
    line-height: 0.8;
  }
  .page1-text3 {
    text-align: center;
  }
}
</style>
