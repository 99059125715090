<template>
  <div class="privacy-policy-container">
    <app-navbar4 rootClassName="navbar4-root-class-name7"></app-navbar4>
    <div class="privacy-policy-container1 thq-section-padding">
      <div class="privacy-policy-max-width thq-section-max-width">
        <h2 id="privacyPolicy" class="privacy-policy-heading1 thq-heading-2">
          Privacy Policy
        </h2>
        <div class="privacy-policy-container2">
		  
			<p>This privacy notice explains how WealthExpress Limited (t/a Yubaro) collects, uses, and stores any personal information about you.</p>

			<h3>What Information Do We Collect About You and Why?</h3>
			<p>Yubaro collects and uses personal data on the basis that it is in its legitimate interests to do so and your right to privacy is not overridden. We need to do so as part of the performance of our contract with you or where we have a legal obligation to do so.</p>

			<p><strong>1. From Our Clients</strong></p>
			<p>We collect information through the Yubaro app, website, and during our interactions with you throughout our relationship to comply with regulations and perform our contract with you. The provision of your personal data is required in order to enter into a contract with us; however, the provision of information for marketing purposes is voluntary. We may record and monitor telephone calls made to or by us in order to comply with regulatory obligations and training purposes. Automated decision-making may be used in order to comply with our KYC/AML checks or to evidence that Yubaro is providing a suitable and appropriate service to its clients.</p>

			<p><strong>2. From Our Website</strong></p>
			<p>When you visit our website, we may collect information about your visit such as your IP address and the pages you visited, and when you use our service, we may collect information on how you use our service.</p>

			<h3>How Do We Process Your Information?</h3>
			<p>All information is processed both manually and electronically in compliance with current data protection regulations. We allow only authorised Yubaro employees and carefully selected Third-Party Affiliates to have access to your information. Such employees and Third-Party Affiliates are appropriately designated and trained to process data only according to the instructions we provide them. Please note that our offices will have CCTV and record your image upon any in-person meeting at our London office.</p>

			<h3>How Do We Store Your Information?</h3>
			<p>Yubaro will retain personal data for a reasonable period considering legitimate business needs to capture and retain such information. Information will also be retained for a period necessary to comply with local regulations or country-specific regulations and requirements and in accordance with Yubaro’s Document Retention Schedule.</p>

			<h3>How Long Do We Keep This Information?</h3>
			<p><strong>Non-clients:</strong> If you are not a client of Yubaro we will retain your information for one year after our last interaction with you. If you ask us to remove you from our marketing list we may keep your name and communication details on an “opt-out” list in order to ensure that we do not market to you again.</p>
			<p><strong>Clients:</strong> If you are or were a Yubaro client we keep most of your information for five (5) years after the closure of your account and the sale of any assets held under it. Where required by law or regulation we may keep documents longer than the designated five (5) years.</p>

			<h3>Who Do We Share Your Information With?</h3>
			<ul>
				<li>Our IT providers and all other service providers and Third-Party Affiliates in order to provide and maintain the provision of our services.</li>
				<li>Our appointed auditors, accountants, lawyers, and other professional advisers to the extent that they require access to the information to provide advice.</li>
				<li>Fraud prevention agencies and other organisations to allow us to undertake relevant checks.</li>
				<li>If required to do so to meet applicable law, the order of a court, or rules and codes of practice applicable to the circumstances at the time.</li>
				<li>Relevant authorities to investigate or prevent fraud or activities believed to be illegal or otherwise in breach of applicable law.</li>
				<li>If tax regulations require us to collect information about tax residency, then in certain circumstances (including if we do not receive a valid self-certification from you) we may be obliged to share information about your passion product and collectible profile with the relevant tax payments and customs authority who may pass this onto tax authorities in other jurisdictions.</li>
				<li>In the event that we sell or buy any business or assets, in which case we will disclose your personal information to the prospective seller or buyer of such business or assets.</li>
				<li>If assets including personal information held by our Third-Party Affiliates are acquired by a third party, personal information may be transferred as part of the acquisition.</li>
			</ul>
			<p>We will not lend or sell your information to undisclosed Third-Party Affiliates. Your personal information may need to be shared with our service providers, which may involve transferring it to countries outside the European Economic Area (EEA). Where we do so, we will ensure that we do this in accordance with current data protection legislation by only transferring your data to jurisdictions in respect of which there is a European Commission adequacy decision or where this is not the case by using model clauses which have been approved by the European Commission.</p>

			<h3>Lawful Basis for Processing</h3>
			<p>We process your personal data based on the following lawful bases:</p>
			<ul>
				<li><strong>Consent:</strong> Where you have given explicit consent for processing your data for specific purposes.</li>
				<li><strong>Contract:</strong> Where processing is necessary for the performance of a contract with you.</li>
				<li><strong>Legal Obligation:</strong> Where processing is necessary to comply with a legal obligation.</li>
				<li><strong>Legitimate Interests:</strong> Where processing is necessary for the purposes of legitimate interests pursued by us or a third party, provided that such interests are not overridden by your interests or fundamental rights and freedoms.</li>
			</ul>

			<h3>Your Rights</h3>
			<p>You have certain rights in respect of the data we hold. These include:</p>
			<ul>
				<li><strong>Right to Access:</strong> You have the right to request a copy of the information we hold about you.</li>
				<li><strong>Right to Rectification:</strong> You have the right to request correction of inaccurate or incomplete information.</li>
				<li><strong>Right to Erasure:</strong> You have the right to request the deletion of your personal data.</li>
				<li><strong>Right to Restrict Processing:</strong> You have the right to request restriction of processing your data.</li>
				<li><strong>Right to Data Portability:</strong> You have the right to request the transfer of your data to another data controller.</li>
				<li><strong>Right to Object:</strong> You have the right to object to the processing of your personal data.</li>
				<li><strong>Rights Related to Automated Decision Making and Profiling:</strong> Where processing is necessary for the purposes of legitimate interests pursued by us or a third party, provided that such interests are not overridden by your interests or fundamental rights and freedoms.</li>
			</ul>
			<p>Please note that the application of these rights varies according to the legal basis used to process your data. In certain circumstances, we are required to retain copies of information we hold about you by other regulations. In this instance, we will not be able to erase or modify the data.</p>
			<p>To exercise these rights or if you have any concerns about our use of your personal information, please contact the Data Protection Officer using the information at the end of this notice.</p>

			<h3>Security</h3>
			<p>Internet communications, including emails, are not entirely secure. We employ encryption methods to protect data during transmission, provided that current protocols are used and correct procedures are followed for encryption and decryption. We cannot accept any responsibility for unauthorized access by a third party or for the loss, theft, or modification of data while it is being sent to us. For security purposes, we may monitor emails received or issued by us.</p>

			<h3>Other Websites</h3>
			<p>This privacy notice only applies to our website and application. When you use a link to another website, you should read the privacy policy of that site.</p>

			<h3>Complaint Process</h3>
			<p>If you believe your data protection rights have been violated, we encourage you to contact us first to resolve any concerns. You can reach us at <a href="mailto:complaints@yubaro.io">complaints@yubaro.io</a>. If you are not satisfied with our response, you have the right to lodge a complaint with a supervisory authority. In the UK, this is the Information Commissioner's Office (ICO).</p>

			<h3>Changes to Our Privacy Notice</h3>
			<p>We keep our privacy notice under regular review. We reserve the right to update this privacy notice at any time and we will advise you when we make any substantial changes to it.</p>

			<h3>How to Contact Us</h3>
			<p>If you have any questions about our privacy notice or the information we hold about you, please contact us at <a href="mailto:help@yubaro.io">help@yubaro.io</a> or by post at the address below:</p>
			<p>Data Protection Officer<br>
			WealthExpress Limited<br>
			70 Baker Street<br>
			London W1U 7DL<br>
			Tel: +44 (0) 208 154 7034</p>

			<p>Last Updated: 5th June 2024</p>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
    <cookie-consent-banner
      rootClassName="cookie-consent-banner-root-class-name6"
    ></cookie-consent-banner>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AppFooter from '../components/footer'
import CookieConsentBanner from '../components/cookie-consent-banner'

export default {
  name: 'PrivacyPolicy',
  props: {},
  components: {
    AppNavbar4,
    AppFooter,
    CookieConsentBanner,
  },
  data() {
    return {

      rawjrwn: ' ',
      raw9dnj: ' ',
      raw4i6j: ' ',
      rawxt39: ' ',
      raww0cn: ' ',
      raweei1: ' ',

    }
  },
  metaInfo: {
    title: 'Privacy Policy - Yubaro',
    meta: [
      {
        name: 'description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        property: 'og:title',
        content: 'Privacy Policy - Yubaro',
      },
      {
        property: 'og:description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
    ],
  },
}
</script>

<style scoped>
.privacy-policy-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #e0d5bb;
}
.privacy-policy-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.privacy-policy-heading1 {
  align-self: flex-start;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.privacy-policy-container2 {
  width: 100%;
  font-family: "Poppins";
    line-height: 1.3;
}
.privacy-policy-container2 p,
.privacy-policy-container2 h2,
.privacy-policy-container2 h3{
  font-family: "Poppins";
  margin: 10px 0;
  line-height: 1.3;
}

.privacy-policy-container2 ol,
.privacy-policy-container2 ul {
	padding-inline-start: 40px;
	}

.privacy-policy-container2 ol li,
.privacy-policy-container2 ul li{
	margin-bottom: 3px;
	}
	
</style>
