<template>
  <div class="newsletter-container">
    <app-navbar4 rootClassName="navbar4-root-class-name10"></app-navbar4>
    <div class="newsletter-header76">
      <div class="newsletter-max-width thq-section-max-width">
        <div class="newsletter-column thq-section-padding">
          <h1 id="aboutH" class="newsletter-text thq-heading-1">
            <span>Thank you!</span>
            <br />
          </h1>
          <p class="newsletter-text03 thq-body-large">
            <span>
              Thank you for signing up to our newsletter. We will be in touch
              shortly with the latest product updates and news from our team. 
            </span>
            <br />
            <br />
            <span>
              If you haven&apos;t already, make sure you download our app and
              create an account in order to access all the latest offers available
              on our marketplace: 
            </span>
            <br />
          </p>
          <div class="newsletter-actions">
            <button class="thq-button-filled newsletter-button">
              <span class="newsletter-text09 thq-body-small">
                Download our App
              </span>
            </button>
            <button class="thq-button-outline newsletter-button1">
              <span class="newsletter-text10 thq-body-small">View on Web</span>
            </button>
          </div>
        </div>
        <about-image-section
          rootClassName="about-image-section-root-class-name1"
        ></about-image-section>
      </div>
    </div>
    <contact-us-bottom></contact-us-bottom>
    <app-footer></app-footer>
    <cookie-consent-banner
      rootClassName="cookie-consent-banner-root-class-name5"
    ></cookie-consent-banner>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AboutImageSection from '../components/about-image-section'
import ContactUsBottom from '../components/contact-us-bottom'
import AppFooter from '../components/footer'
import CookieConsentBanner from '../components/cookie-consent-banner'

export default {
  name: 'Newsletter',
  props: {},
  components: {
    AppNavbar4,
    AboutImageSection,
    ContactUsBottom,
    AppFooter,
    CookieConsentBanner,
  },
  metaInfo: {
    title: 'Newsletter Sign up Complete - Yubaro',
    meta: [
      {
        name: 'description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        property: 'og:title',
        content: 'Newsletter Sign up Complete - Yubaro',
      },
      {
        property: 'og:description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  },
}
</script>

<style scoped>
.newsletter-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #e0d5bb;
}
.newsletter-header76 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
}
.newsletter-max-width {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.newsletter-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.newsletter-text {
  z-index: 100;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 1.2;
}
.newsletter-text03 {
  font-family: "Poppins";
}
.newsletter-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.newsletter-text09 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
}
.newsletter-text10 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
}
@media(max-width: 1600px) {
  .newsletter-text {
    line-height: 1.1;
  }
}
@media(max-width: 991px) {
  .newsletter-header76 {
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .newsletter-max-width {
    flex-direction: column;
  }
  .newsletter-column {
    width: 100%;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
}
@media(max-width: 767px) {
  .newsletter-column {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .newsletter-text {
    width: 100%;
    text-align: center;
  }
  .newsletter-text03 {
    text-align: center;
  }
  .newsletter-actions {
    width: 100%;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .newsletter-column {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .newsletter-text {
    text-align: center;
  }
  .newsletter-text03 {
    text-align: center;
  }
  .newsletter-actions {
    flex-direction: column;
  }
  .newsletter-button {
    width: 100%;
  }
  .newsletter-button1 {
    width: 100%;
  }
}
</style>
