<template>
  <header class="navbar4-container" v-bind:class="rootClassName">
    <header data-thq="thq-navbar" class="navbar4-navbar-interactive">
      <div>
        <router-link to="/" class="navbar4-navlink">
          <img
            alt="image"
            src="/logo-whitetext-200h.webp"
            class="navbar4-image"
          />
        </router-link>
      </div>
      <div data-thq="thq-navbar-nav" class="navbar4-desktop-menu">
        <nav class="navbar4-links">
          <router-link to="/" id="homelink" class="navbar4-link1 thq-body-small">
            Home
          </router-link>
          <router-link
            to="/about-us"
            id="aboutlink"
            class="navbar4-link2 thq-body-small"
          >
            About
          </router-link>
          <a
            href="https://medium.com/yubaro"
            target="_blank"
            rel="noreferrer noopener"
            class="navbar4-link4 thq-body-small"
          >
            News/Webinars
          </a>
          <router-link
            to="/download"
            id="downloadlink"
            class="navbar4-link5 thq-body-small"
          >
            Download
          </router-link>
          <router-link
            to="/contact"
            id="contactlink"
            class="navbar4-link3 thq-body-small"
          >
            Contact
          </router-link>
        </nav>
        <div class="navbar4-buttons">
          <a
            href="https://app.yubaro.io"
            target="_blank"
            rel="noreferrer noopener"
            class="navbar4-action2 thq-button-outline thq-button-animated"
          >
            <span class="navbar4-text thq-body-small">Access Our App</span>
          </a>
        </div>
      </div>
      <div data-thq="thq-burger-menu" class="navbar4-burger-menu">
        <svg viewBox="0 0 1024 1024" class="navbar4-icon">
          <path
            d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
          ></path>
        </svg>
      </div>
      <div data-thq="thq-mobile-menu" class="navbar4-mobile-menu">
        <div class="navbar4-nav">
          <div class="navbar4-top">
            <img
              alt="image"
              src="/logo-whitetext-200h.webp"
              class="navbar4-image1"
            />
            <div data-thq="thq-close-menu" class="navbar4-close-menu">
              <svg viewBox="0 0 1024 1024" class="navbar4-icon2">
                <path
                  d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                ></path>
              </svg>
            </div>
          </div>
          <nav class="navbar4-links1">
            <router-link to="/" class="navbar4-link11 thq-body-small">
              Home
            </router-link>
            <router-link to="/about-us" class="navbar4-link21 thq-body-small">
              About
            </router-link>
            <a
              href="https://medium.com/yubaro/"
              target="_blank"
              rel="noreferrer noopener"
              class="navbar4-link41 thq-body-small"
            >
              News/Webinars
            </a>
            <router-link to="/contact" class="navbar4-link31 thq-body-small">
              Contact
            </router-link>
            <router-link to="/download" class="navbar4-link51 thq-body-small">
              Download
            </router-link>
          </nav>
        </div>
        <div class="navbar4-buttons1">
          <a
            href="https://app.yubaro.io"
            target="_blank"
            rel="noreferrer noopener"
            class="navbar4-action21 thq-button-outline"
          >
            Use our Web App
          </a>
        </div>
      </div>
    </header>
  </header>
</template>

<script>
export default {
  name: 'Navbar4',
  props: {
    rootClassName: String,
  },
}
</script>

<style scoped>
.navbar4-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar4-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-yubarobrandcolours-primarytext);
}
.navbar4-image1 {
  width: 220px;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.navbar4-navlink {
  display: contents;
}
.navbar4-image {
  width: 155px;
  height: 34px;
  object-fit: cover;
  text-decoration: none;
}
.navbar4-desktop-menu {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar4-links {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  height: 22px;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.navbar4-link1 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  font-style: normal;
  text-align: left;
  transition: 0.3s;
  font-family: "Poppins";
  font-weight: 400;
  text-decoration: none;
}
.navbar4-link1:hover {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 5px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
}
.navbar4-link1:active {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 5px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 4px;
}
.navbar4-link2 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  font-style: normal;
  text-align: left;
  transition: 0.3s;
  font-family: "Poppins";
  font-weight: 400;
  text-decoration: none;
  border-right-width: 0px;
}
.navbar4-link2:hover {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 5px;
}
.navbar4-link2:active {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
}
.navbar4-link4 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  font-style: normal;
  text-align: left;
  transition: 0.3s;
  font-family: "Poppins";
  font-weight: 400;
  text-decoration: none;
}
.navbar4-link4:hover {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
}
.navbar4-link4:active {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
}
.navbar4-link5 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  font-style: normal;
  text-align: left;
  transition: 0.3s;
  font-family: "Poppins";
  font-weight: 400;
  text-decoration: none;
}
.navbar4-link5:hover {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
}
.navbar4-link5:active {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
}
.navbar4-link3 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  font-style: normal;
  text-align: left;
  transition: 0.3s;
  font-family: "Poppins";
  font-weight: 400;
  text-decoration: none;
}
.navbar4-link3:hover {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
}
.navbar4-link3:active {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
}
.navbar4-buttons {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
}
.navbar4-action2 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  display: flex;
  flex-direction: row;
  text-decoration: none;
}
.navbar4-text {
  fill: var(--dl-color-yubarobrandcolours-primary);
  color: var(--dl-color-yubarobrandcolours-primary);
  font-style: normal;
  font-family: "Poppins";
  font-weight: 700;
}
.navbar4-burger-menu {
  display: none;
}
.navbar4-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar4-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  background-color: var(--dl-color-yubarobrandcolours-primarytext);
}
.navbar4-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar4-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar4-image1 {
  width: 200px;
  object-fit: cover;
}
.navbar4-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar4-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar4-links1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.navbar4-link11 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  transition: 0.3s;
  font-family: "Poppins";
  text-decoration: none;
}
.navbar4-link11:active {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
}
.navbar4-link21 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  transition: 0.3s;
  font-family: "Poppins";
  text-decoration: none;
}
.navbar4-link21:active {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 5px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar4-link41 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  transition: 0.3s;
  font-family: "Poppins";
  text-decoration: none;
}
.navbar4-link41:active {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
}
.navbar4-link31 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  transition: 0.3s;
  font-family: "Poppins";
  text-decoration: none;
}
.navbar4-link31:active {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
}
.navbar4-link51 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  transition: 0.3s;
  font-family: "Poppins";
  text-decoration: none;
}
.navbar4-link51:active {
  border-color: var(--dl-color-yubarobrandcolours-primary);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 5px;
}
.navbar4-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
}
.navbar4-action21 {
  text-decoration: none;
}
.navbar4-root-class-name {
  background-color: #020a0a;
}
.navbar4-root-class-name1 {
  background-color: #020a0a;
}
.navbar4-root-class-name2 {
  background-color: #020a0a;
}
.navbar4-root-class-name3 {
  background-color: #020a0a;
}
.navbar4-root-class-name4 {
  background-color: #020a0a;
}
.navbar4-root-class-name5 {
  background-color: #020a0a;
}
.navbar4-root-class-name6 {
  background-color: #020a0a;
}
.navbar4-root-class-name7 {
  background-color: #020a0a;
}
.navbar4-root-class-name8 {
  background-color: #020a0a;
}
.navbar4-root-class-name9 {
  background-color: #020a0a;
}
.navbar4-root-class-name10 {
  background-color: #020a0a;
}
.navbar4-root-class-name11 {
  background-color: #020a0a;
}
.navbar4-root-class-name12 {
  background-color: #020a0a;
}
.navbar4-root-class-name13 {
  background-color: #020a0a;
}
.navbar4-root-class-name14 {
  background-color: #020a0a;
}
.navbar4-root-class-name15 {
  background-color: #020a0a;
}
@media(max-width: 1600px) {
  .navbar4-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 1200px) {
  .navbar4-navbar-interactive {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 991px) {
  .navbar4-image1 {
    width: 161px;
    height: 41px;
  }
  .navbar4-image {
    width: 157px;
    height: 35px;
  }
}
@media(max-width: 767px) {
  .navbar4-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar4-image1 {
    width: 157px;
    height: 35px;
  }
  .navbar4-desktop-menu {
    display: none;
  }
  .navbar4-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar4-icon {
    fill: #D9D9D9;
    width: 65px;
    height: 35px;
  }
  .navbar4-mobile-menu {
    background-color: var(--dl-color-yubarobrandcolours-primarytext);
  }
  .navbar4-icon2 {
    fill: #D9D9D9;
    width: 34px;
    height: 34px;
  }
  .navbar4-link11 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
  }
  .navbar4-link21 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
  }
  .navbar4-link41 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
  }
  .navbar4-link31 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
  }
  .navbar4-link51 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
  }
}
@media(max-width: 479px) {
  .navbar4-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navbar4-image1 {
    width: 117px;
    height: 26px;
  }
  .navbar4-image {
    width: 113px;
    height: 25px;
  }
  .navbar4-icon {
    fill: #D9D9D9;
    width: 36px;
    height: 33px;
  }
  .navbar4-mobile-menu {
    padding: var(--dl-space-space-unit);
    z-index: 999;
    background-color: var(--dl-color-yubarobrandcolours-primarytext);
  }
  .navbar4-icon2 {
    fill: #D9D9D9;
    width: 35px;
    height: 31px;
  }
  .navbar4-link11 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
  }
  .navbar4-link21 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
  }
  .navbar4-link41 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
  }
  .navbar4-link31 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
  }
  .navbar4-link51 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
  }
}
</style>
