<template>
  <div class="about-image-section-content" v-bind:class="rootClassName">
    <div class="about-image-section-column">
      <img
        :alt="placeholderImageAlt"
        :src="placeholderImageSrc"
        class="about-image-section-placeholder-image thq-img-ratio-1-1"
      />
      <img
        :alt="placeholderImageAlt1"
        :src="placeholderImageSrc1"
        class="about-image-section-placeholder-image1 thq-img-ratio-1-1"
      />
      <img
        :alt="placeholderImageAlt2"
        :src="placeholderImageSrc2"
        class="about-image-section-placeholder-image2 thq-img-ratio-1-1"
      />
    </div>
    <div class="about-image-section-column1">
      <img
        :alt="placeholderImageAlt3"
        :src="placeholderImageSrc3"
        class="about-image-section-placeholder-image3 thq-img-ratio-1-1"
      />
      <img
        :alt="placeholderImageAlt4"
        :src="placeholderImageSrc4"
        class="about-image-section-placeholder-image4 thq-img-ratio-1-1"
      />
      <img
        :alt="placeholderImageAlt5"
        :src="placeholderImageSrc5"
        class="about-image-section-placeholder-image5 thq-img-ratio-1-1"
      />
      <img
        :alt="placeholderImageAlt6"
        :src="placeholderImageSrc6"
        class="about-image-section-placeholder-image6 thq-img-ratio-1-1"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutImageSection',
  props: {
    placeholderImageAlt6: {
      type: String,
      default: 'Hero Image',
    },
    placeholderImageSrc4: {
      type: String,
      default: '/about-us/photos/erol-ahmed-xsq8nukpao4-unsplash-tablet.webp',
    },
    placeholderImageAlt3: {
      type: String,
      default: 'Hero Image',
    },
    placeholderImageSrc5: {
      type: String,
      default:
        '/about-us/photos/fawazlul-rizqi-jkud3suxcmw-unsplash-tablet.webp',
    },
    placeholderImageAlt2: {
      type: String,
      default: 'Hero Image',
    },
    placeholderImageAlt4: {
      type: String,
      default: 'Hero Image',
    },
    placeholderImageAlt1: {
      type: String,
      default: 'Hero Image',
    },
    placeholderImageSrc: {
      type: String,
      default: '/about-us/photos/dan-burton-sdogdunfcjo-unsplash-tablet.webp',
    },
    placeholderImageSrc6: {
      type: String,
      default: '/about-us/photos/kent-lam-y0rrb835wjg-unsplash-tablet.webp',
    },
    rootClassName: String,
    placeholderImageAlt5: {
      type: String,
      default: 'Hero Image',
    },
    placeholderImageSrc2: {
      type: String,
      default: '/about-us/photos/martin-pechy-r7-ccjcro48-unsplash-tablet.webp',
    },
    placeholderImageSrc1: {
      type: String,
      default: '/about-us/photos/marvin-l-61szczl3mlk-unsplash-tablet.webp',
    },
    placeholderImageSrc3: {
      type: String,
      default:
        '/about-us/photos/trevor-gerzen-fdfwkzw0hf4-unsplash-tablet.webp',
    },
    placeholderImageAlt: {
      type: String,
      default: 'Hero Image',
    },
  },
}
</script>

<style scoped>
.about-image-section-content {
  gap: var(--dl-space-space-unit);
  width: 50%;
  height: 900px;
  display: flex;
  position: relative;
  align-items: center;
}
.about-image-section-column {
  gap: 16px;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.about-image-section-placeholder-image {
  width: 100%;
  height: 400px;
  align-self: stretch;
  border-radius: var(--dl-radius-radius-radius4);
}
.about-image-section-placeholder-image1 {
  width: 100%;
  height: 400px;
  align-self: stretch;
  border-radius: var(--dl-radius-radius-radius4);
}
.about-image-section-placeholder-image2 {
  width: 100%;
  height: 400px;
  align-self: stretch;
  border-radius: var(--dl-radius-radius-radius4);
}
.about-image-section-column1 {
  gap: 16px;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.about-image-section-placeholder-image3 {
  width: 100%;
  height: 400px;
  align-self: stretch;
  border-radius: var(--dl-radius-radius-radius4);
}
.about-image-section-placeholder-image4 {
  width: 100%;
  height: 400px;
  align-self: stretch;
  border-radius: var(--dl-radius-radius-radius4);
}
.about-image-section-placeholder-image5 {
  width: 100%;
  height: 400px;
  align-self: stretch;
  border-radius: var(--dl-radius-radius-radius4);
}
.about-image-section-placeholder-image6 {
  width: 100%;
  height: 400px;
  align-self: stretch;
  border-radius: var(--dl-radius-radius-radius4);
}



@media(max-width: 991px) {
  .about-image-section-content {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .about-image-section-column {
    width: auto;
    margin-top: 0px;
    margin-left: -100px;
    flex-direction: row;
  }
  .about-image-section-placeholder-image {
    width: 400px;
  }
  .about-image-section-placeholder-image1 {
    width: 400px;
  }
  .about-image-section-placeholder-image2 {
    width: 400px;
  }
  .about-image-section-column1 {
    width: auto;
    margin-top: 0;
    margin-left: -100px;
    flex-direction: row;
  }
  .about-image-section-placeholder-image3 {
    width: 400px;
  }
  .about-image-section-placeholder-image4 {
    width: 400px;
  }
  .about-image-section-placeholder-image5 {
    width: 400px;
  }
  .about-image-section-placeholder-image6 {
    width: 400px;
    display: none;
  }
}
@media(max-width: 767px) {
  .about-image-section-column {
    width: 100%;
    margin-left: 0px;
  }
  .about-image-section-placeholder-image {
    height: 500px;
  }
  .about-image-section-placeholder-image1 {
    height: 500px;
  }
  .about-image-section-placeholder-image2 {
    height: 500px;
  }
  .about-image-section-column1 {
    width: 100%;
    margin-left: 0px;
  }
  .about-image-section-placeholder-image3 {
    height: 500px;
  }
  .about-image-section-placeholder-image4 {
    height: 500px;
  }
  .about-image-section-placeholder-image5 {
    height: 500px;
  }
}
@media(max-width: 479px) {
  .about-image-section-placeholder-image {
    width: var(--dl-size-size-xlarge);
    height: var(--dl-size-size-xlarge);
  }
  .about-image-section-placeholder-image1 {
    width: var(--dl-size-size-xlarge);
    height: var(--dl-size-size-xlarge);
  }
  .about-image-section-placeholder-image2 {
    width: var(--dl-size-size-xlarge);
    height: var(--dl-size-size-xlarge);
  }
  .about-image-section-placeholder-image3 {
    width: var(--dl-size-size-xlarge);
    height: var(--dl-size-size-xlarge);
  }
  .about-image-section-placeholder-image4 {
    width: var(--dl-size-size-xlarge);
    height: var(--dl-size-size-xlarge);
  }
  .about-image-section-placeholder-image5 {
    width: var(--dl-size-size-xlarge);
    height: var(--dl-size-size-xlarge);
  }
}
</style>
