<template>
  <div class="view-cookies-container">
    <app-navbar4 rootClassName="navbar4-root-class-name12"></app-navbar4>
    <div class="view-cookies-container1 thq-section-padding">
      <div class="view-cookies-max-width thq-section-max-width">
        <h2 id="termsConditions" class="view-cookies-heading1 thq-heading-2">
          Cookies
        </h2>
        <div class="view-cookies-container2">
          <p class="view-cookies-content1 thq-body-small">
            <span class="view-cookies-text">Introduction</span>
            <br />
            <span>
              Yubaro.io (&quot;we,&quot; &quot;us,&quot; &quot;our&quot;) uses
              cookies to improve and customize your browsing experience on our
              website. This Cookie Policy explains what cookies are, how we use
              them, and your choices regarding their use.
            </span>
            <br />
            <br />
            <span class="view-cookies-text05">What are Cookies?</span>
            <br />
            <span>
              Cookies are small text files stored on your device (computer,
              tablet, or mobile) when you visit a website. They are widely used to
              make websites work more efficiently and provide information to the
              owners of the site.
            </span>
            <br />
            <br />
            <span class="view-cookies-text10">Types of Cookies We Use</span>
            <br />
            <span>We use the following types of cookies on Yubaro.io:</span>
            <br />
            <br />
            <span class="view-cookies-text15">1. Essential Cookies</span>
            <br />
            <span>
              These cookies are necessary for the website to function properly.
              They enable core functionalities such as security, network
              management, and accessibility. Without these cookies, certain parts
              of our site won’t work as intended.
            </span>
            <br />
            <br />
            <span class="view-cookies-text20">
              Our essential cookies include:
            </span>
            <br />
            <span class="view-cookies-text22">cf_clearance</span>
            <br />
            <span>Domain: yubaro.io</span>
            <br />
            <span>Duration: 1 year.</span>
            <br />
            <span>
              Cloudflare places this cookie on end-user devices that access
              customer sites and store proof that the challenge was passed. It is
              used to no longer issue a challenge if present. It is required to
              visit our website.
            </span>
            <br />
            <br />
            <span class="view-cookies-text31">rc::a</span>
            <br />
            <span>Domain: google.com</span>
            <br />
            <span>Duration: never</span>
            <br />
            <span>
              This cookie is set by the Google recaptcha service to identify bots
              to protect the website against malicious spam attacks.
            </span>
            <br />
            <br />
            <span class="view-cookies-text40">rc::c</span>
            <br />
            <span>Domain: google.com</span>
            <br />
            <span>Duration: session</span>
            <br />
            <span>
              This cookie is set by the Google recaptcha service to identify bots
              to protect the website against malicious spam attacks.
            </span>
            <br />
            <br />
            <span class="view-cookies-text49">2. Performance Cookies</span>
            <br />
            <span>
              Performance cookies collect information about how visitors use our
              website. They help us understand which pages are most and least
              popular and see how visitors move around the site. This data is used
              to improve how the website works.
            </span>
            <br />
            <br />
            <span class="view-cookies-text54">3. Functionality Cookies</span>
            <br />
            <span>
              These cookies allow our website to remember choices you make (such
              as your username, language, or the region you are in) and provide
              enhanced, more personalized features. They may be set by us or by
              third-party providers whose services we have added to our pages.
            </span>
            <br />
            <br />
            <span class="view-cookies-text59">
              4. Targeting/Advertising Cookies
            </span>
            <br />
            <span>
              These cookies are used to deliver advertisements more relevant to
              you and your interests. They may also be used to limit the number of
              times you see an advertisement and help measure the effectiveness of
              an advertising campaign. They are usually placed by advertising
              networks with our permission.
            </span>
            <br />
            <br />
            <span class="view-cookies-text64">Third-Party Cookies</span>
            <br class="view-cookies-text65" />
            <span>
              In addition to our own cookies, we may also use various third-party
              cookies to report usage statistics of the website and deliver
              advertisements on and through the website.
            </span>
            <br />
            <br />
            <span class="view-cookies-text69">
              Your Choices Regarding Cookies
            </span>
            <br />
            <span>
              You have the right to decide whether to accept or reject cookies.
              You can exercise your cookie preferences by setting or amending your
              web browser controls to accept or refuse cookies. If you choose to
              reject cookies, you may still use our website, though your access to
              some functionality and areas of our website may be restricted.
            </span>
            <br />
            <br />
            <span class="view-cookies-text74">How to Manage Cookies</span>
            <br />
            <span>
              Most web browsers allow control over cookies through their settings
              preferences. To find out more about cookies, including how to see
              what cookies have been set, visit www.aboutcookies.org or
              www.allaboutcookies.org.
            </span>
            <br />
            <br />
            <span class="view-cookies-text79">Changing Your Cookie Settings</span>
            <br />
            <span>
              The settings for managing cookies can typically be found in the
              &quot;options&quot; or &quot;preferences&quot; menu of your browser.
              To understand these settings, the following links may be helpful.
              Otherwise, you should use the &quot;Help&quot; option in your
              browser for more details.
            </span>
            <br />
            <br />
            <span class="view-cookies-text84">Updates to This Cookie Policy</span>
            <br />
            <span>
              We may update our Cookie Policy from time to time to reflect changes
              in our practices or for other operational, legal, or regulatory
              reasons. Please revisit this Cookie Policy regularly to stay
              informed about our use of cookies and related technologies.
            </span>
            <br />
          </p>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
    <cookie-consent-banner
      rootClassName="cookie-consent-banner-root-class-name8"
    ></cookie-consent-banner>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AppFooter from '../components/footer'
import CookieConsentBanner from '../components/cookie-consent-banner'

export default {
  name: 'ViewCookies',
  props: {},
  components: {
    AppNavbar4,
    AppFooter,
    CookieConsentBanner,
  },
  metaInfo: {
    title: 'Cookies - Yubaro',
    meta: [
      {
        name: 'description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        property: 'og:title',
        content: 'Cookies - Yubaro',
      },
      {
        property: 'og:description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
    ],
  },
}
</script>

<style scoped>
.view-cookies-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #e0d5bb;
}
.view-cookies-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.view-cookies-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.view-cookies-heading1 {
  align-self: flex-start;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.view-cookies-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.view-cookies-content1 {
  font-family: "Poppins";
}
.view-cookies-text {
  font-size: 18px;
  font-weight: 700;
}
.view-cookies-text05 {
  font-size: 18px;
  font-weight: 700;
}
.view-cookies-text10 {
  font-size: 18px;
  font-weight: 700;
}
.view-cookies-text15 {
  font-weight: 700;
}
.view-cookies-text20 {
  font-style: italic;
}
.view-cookies-text22 {
  font-weight: 700;
}
.view-cookies-text31 {
  font-weight: 700;
}
.view-cookies-text40 {
  font-weight: 700;
}
.view-cookies-text49 {
  font-weight: 700;
}
.view-cookies-text54 {
  font-weight: 700;
}
.view-cookies-text59 {
  font-weight: 700;
}
.view-cookies-text64 {
  font-size: 18px;
  font-weight: 700;
}
.view-cookies-text65 {
  font-weight: 700;
}
.view-cookies-text69 {
  font-size: 18px;
  font-weight: 700;
}
.view-cookies-text74 {
  font-size: 18px;
  font-weight: 700;
}
.view-cookies-text79 {
  font-size: 18px;
  font-weight: 700;
}
.view-cookies-text84 {
  font-size: 18px;
  font-weight: 700;
}
</style>
