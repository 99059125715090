import { render, staticRenderFns } from "./faq-component.vue?vue&type=template&id=645450b2&scoped=true"
import script from "./faq-component.vue?vue&type=script&lang=js"
export * from "./faq-component.vue?vue&type=script&lang=js"
import style0 from "./faq-component.vue?vue&type=style&index=0&id=645450b2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "645450b2",
  null
  
)

export default component.exports