<template>
  <div class="faq-component-faq12 thq-section-padding">
    <div class="faq-component-max-width thq-section-max-width">
      <div class="faq-component-list-item10"></div>
      <div class="faq-component-section-title thq-flex-column">
        <h2 class="faq-component-text thq-heading-2">{{ heading1 }}</h2>
        <p class="faq-component-text1 thq-body-large">{{ content1 }}</p>
      </div>
      <div class="faq-component-content thq-flex-row">
        <div class="faq-component-container">
          <div class="thq-flex-column">
            <div class="faq-component-list-item1">
              <p class="faq-component-faq1-question thq-body-large">
                {{ faq1Question }}
              </p>
              <span class="faq-component-faq1-answer thq-body-small">
                {{ faq1Answer }}
              </span>
            </div>
            <div class="faq-component-list-item2">
              <p class="faq-component-faq2-question thq-body-large">
                {{ faq2Question }}
              </p>
              <span class="faq-component-faq2-answer thq-body-small">
                {{ faq2Answer }}
              </span>
            </div>
            <div class="faq-component-list-item3">
              <p class="faq-component-faq3-question thq-body-large">
                {{ faq3Question }}
              </p>
              <span class="faq-component-faq3-answer thq-body-small">
                {{ faq3Answer }}
              </span>
            </div>
            <div class="faq-component-list-item4">
              <p class="faq-component-faq4-question thq-body-large">
                {{ faq4Question }}
              </p>
              <span class="faq-component-faq4-answer thq-body-small">
                {{ faq4Answer }}
              </span>
            </div>
          </div>
        </div>
        <div class="thq-flex-column">
          <div class="faq-component-list-item6">
            <p class="faq-component-faq1-question1 thq-body-large">
              {{ faq6Question }}
            </p>
            <span class="faq-component-faq1-answer1 thq-body-small">
              {{ faq6Answer }}
            </span>
          </div>
          <div class="faq-component-list-item7">
            <p class="faq-component-faq2-question1 thq-body-large">
              {{ faq7Question }}
            </p>
            <span class="faq-component-faq2-answer1 thq-body-small">
              {{ faq7Answer }}
            </span>
          </div>
          <div class="faq-component-list-item8">
            <p class="faq-component-faq3-question1 thq-body-large">
              {{ faq8Question }}
            </p>
            <span class="faq-component-faq3-answer1 thq-body-small">
              {{ faq8Answer }}
            </span>
          </div>
          <div class="faq-component-list-item5">
            <p class="faq-component-faq5-question thq-body-large">
              {{ faq5Question }}
            </p>
            <span class="faq-component-faq5-answer thq-body-small">
              {{ faq5Answer }}
            </span>
          </div>
        </div>
      </div>
      <div class="faq-component-list-item101">
        <div class="faq-component-list-item102"></div>
      </div>
      <div class="faq-component-content1 thq-flex-column">
        <div class="faq-component-content2">
          <h2 class="faq-component-text2 thq-heading-2">{{ heading2 }}</h2>
          <p class="faq-component-text3 thq-body-large">
            It is important to carefully consider your risk tolerance and goals
            before engaging in any activities.
          </p>
        </div>
        <router-link
          to="/capital-risk-disclosure"
          class="faq-component-button thq-button-outline"
        >
          <span class="faq-component-action1 thq-body-small">
            Click here to view our risk disclosure
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQComponent',
  props: {
    heading2: {
      type: String,
      default: 'Your Capital is at Risk',
    },
    heading1: {
      type: String,
      default: 'Frequently Asked Questions',
    },
    faq6Answer: {
      type: String,
      default:
        'Yes, investing in any financial instrument involves risk, including the potential loss of principal.',
    },
    faq8Question: {
      type: String,
      default: 'Are there any guarantees on returns with Yubaro investments?',
    },
    faq6Question: {
      type: String,
      default: 'Is my capital at risk when investing through Yubaro?',
    },
    faq5Question: {
      type: String,
      default: 'What should I consider before investing with Yubaro?',
    },
    faq1Question: {
      type: String,
      default: 'What is Yubaro?',
    },
    faq7Answer: {
      type: String,
      default:
        'You can reach out to our customer support team via email or phone for assistance with any queries or concerns.',
    },
    faq1Answer: {
      type: String,
      default:
        'Yubaro is an investment platform that offers a diverse range of alternative assets for portfolio diversification.',
    },
    faq5Answer: {
      type: String,
      default:
        'Before investing, consider your financial goals, risk tolerance, and seek advice from a financial advisor if needed.',
    },
    faq4Question: {
      type: String,
      default: 'How can I stay informed on market trends with Yubaro?',
    },
    content1: {
      type: String,
      default:
        'Investing in alternative assets carries risks. Your capital is at risk and the value of investments can go down as well as up.',
    },
    faq4Answer: {
      type: String,
      default:
        'Yubaro provides regular updates and analysis on market trends to keep users informed.',
    },
    faq8Answer: {
      type: String,
      default:
        'Yubaro does not guarantee returns as investments are subject to market fluctuations and risks.',
    },
    faq2Answer: {
      type: String,
      default:
        'Users can easily track their purchases through their account dashboard on the Yubaro platform.',
    },
    faq2Question: {
      type: String,
      default: 'How can I track my purchases on Yubaro?',
    },
    faq7Question: {
      type: String,
      default: 'How can I contact Yubaro for support?',
    },
    faq3Question: {
      type: String,
      default: 'Are there expert insights available on Yubaro?',
    },
    faq3Answer: {
      type: String,
      default:
        'Yes, users have access to expert insights to help them make informed investment decisions.',
    },
  },
}
</script>

<style scoped>
.faq-component-faq12 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
}
.faq-component-max-width {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.faq-component-list-item10 {
  gap: var(--dl-space-space-unit);
  height: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-imageradius);
  margin-bottom: 0px;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgba(163,89,27,1) 0%, rgba(241,184,96,1) 58%, rgba(244,188,98,1) 74%, rgba(199,130,96,1) 100%);;
}
.faq-component-section-title {
  align-items: flex-start;
}
.faq-component-text {
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 500;
}
.faq-component-text1 {
  text-align: left;
  font-family: "Poppins";
}
.faq-component-content {
  align-items: flex-start;
}
.faq-component-container {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.faq-component-list-item1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq-component-faq1-question {
  font-style: normal;
  text-align: left;
  font-family: "Poppins";
  font-weight: 500;
}
.faq-component-faq1-answer {
  text-align: left;
  font-family: "Poppins";
}
.faq-component-list-item2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq-component-faq2-question {
  font-style: normal;
  text-align: left;
  font-family: "Poppins";
  font-weight: 500;
}
.faq-component-faq2-answer {
  text-align: left;
  font-family: "Poppins";
}
.faq-component-list-item3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq-component-faq3-question {
  font-style: normal;
  text-align: left;
  font-family: "Poppins";
  font-weight: 500;
}
.faq-component-faq3-answer {
  text-align: left;
  font-family: "Poppins";
}
.faq-component-list-item4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq-component-faq4-question {
  font-style: normal;
  text-align: left;
  font-family: "Poppins";
  font-weight: 500;
}
.faq-component-faq4-answer {
  text-align: left;
  font-family: "Poppins";
}
.faq-component-list-item6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq-component-faq1-question1 {
  font-style: normal;
  text-align: left;
  font-family: "Poppins";
  font-weight: 500;
}
.faq-component-faq1-answer1 {
  text-align: left;
  font-family: "Poppins";
}
.faq-component-list-item7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq-component-faq2-question1 {
  font-style: normal;
  text-align: left;
  font-family: "Poppins";
  font-weight: 500;
}
.faq-component-faq2-answer1 {
  text-align: left;
  font-family: "Poppins";
}
.faq-component-list-item8 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq-component-faq3-question1 {
  font-style: normal;
  text-align: left;
  font-family: "Poppins";
  font-weight: 500;
}
.faq-component-faq3-answer1 {
  text-align: left;
  font-family: "Poppins";
}
.faq-component-list-item5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq-component-faq5-question {
  font-style: normal;
  text-align: left;
  font-family: "Poppins";
  font-weight: 500;
}
.faq-component-faq5-answer {
  text-align: left;
  font-family: "Poppins";
}
.faq-component-list-item101 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq-component-list-item102 {
  gap: var(--dl-space-space-unit);
  height: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-imageradius);
  margin-bottom: 0px;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgba(163,89,27,1) 0%, rgba(241,184,96,1) 58%, rgba(244,188,98,1) 74%, rgba(199,130,96,1) 100%);;
}
.faq-component-content1 {
  align-items: flex-start;
}
.faq-component-content2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  flex-direction: column;
}
.faq-component-text2 {
  font-size: 28px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.faq-component-text3 {
  font-size: 16px;
  font-family: "Poppins";
}
.faq-component-button {
  text-decoration: none;
  background-image: linear-gradient(90deg, rgba(163,89,27,1) 0%, rgba(241,184,96,1) 58%, rgba(244,188,98,1) 74%, rgba(199,130,96,1) 100%);;
}
.faq-component-action1 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
@media(max-width: 991px) {
  .faq-component-max-width {
    align-items: center;
  }
  .faq-component-section-title {
    align-items: center;
  }
  .faq-component-text1 {
    text-align: center;
  }
  .faq-component-faq1-question {
    font-style: normal;
    font-weight: 500;
  }
  .faq-component-faq2-question {
    font-style: normal;
    font-weight: 500;
  }
  .faq-component-faq3-question {
    font-style: normal;
    font-weight: 500;
  }
  .faq-component-faq4-question {
    font-style: normal;
    font-weight: 500;
  }
  .faq-component-faq1-question1 {
    font-style: normal;
    font-weight: 500;
  }
  .faq-component-faq2-question1 {
    font-style: normal;
    font-weight: 500;
  }
  .faq-component-faq3-question1 {
    font-style: normal;
    font-weight: 500;
  }
  .faq-component-faq5-question {
    font-style: normal;
    font-weight: 500;
  }
  .faq-component-content1 {
    align-items: center;
  }
  .faq-component-content2 {
    align-items: center;
    justify-content: center;
  }
  .faq-component-text2 {
    font-size: 28px;
    text-align: center;
  }
  .faq-component-text3 {
    font-size: 16px;
    text-align: center;
  }
}
@media(max-width: 767px) {
  .faq-component-section-title {
    align-items: center;
  }
  .faq-component-text {
    font-family: Poppins;
    font-weight: normal;
  }
  .faq-component-text1 {
    font-family: Poppins;
    font-weight: normal;
  }
  .faq-component-content {
    flex-direction: column;
  }
  .faq-component-container {
    width: 100%;
  }
  .faq-component-list-item1 {
    align-items: center;
  }
  .faq-component-faq1-question {
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
  }
  .faq-component-faq1-answer {
    text-align: center;
    font-family: Poppins;
    font-weight: normal;
  }
  .faq-component-list-item2 {
    align-items: center;
  }
  .faq-component-faq2-question {
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
  }
  .faq-component-faq2-answer {
    text-align: center;
    font-family: Poppins;
    font-weight: normal;
  }
  .faq-component-list-item3 {
    align-items: center;
  }
  .faq-component-faq3-question {
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
  }
  .faq-component-faq3-answer {
    text-align: center;
    font-family: Poppins;
    font-weight: normal;
  }
  .faq-component-list-item4 {
    align-items: center;
  }
  .faq-component-faq4-question {
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
  }
  .faq-component-faq4-answer {
    text-align: center;
    font-family: Poppins;
    font-weight: normal;
  }
  .faq-component-list-item6 {
    align-items: center;
  }
  .faq-component-faq1-question1 {
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
  }
  .faq-component-faq1-answer1 {
    text-align: center;
    font-family: Poppins;
    font-weight: normal;
  }
  .faq-component-list-item7 {
    align-items: center;
  }
  .faq-component-faq2-question1 {
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
  }
  .faq-component-faq2-answer1 {
    text-align: center;
    font-family: Poppins;
    font-weight: normal;
  }
  .faq-component-list-item8 {
    align-items: center;
  }
  .faq-component-faq3-question1 {
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
  }
  .faq-component-faq3-answer1 {
    text-align: center;
    font-family: Poppins;
    font-weight: normal;
  }
  .faq-component-list-item5 {
    align-items: center;
  }
  .faq-component-faq5-question {
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
  }
  .faq-component-faq5-answer {
    text-align: center;
    font-family: Poppins;
    font-weight: normal;
  }
  .faq-component-content1 {
    align-items: center;
  }
  .faq-component-content2 {
    align-items: center;
  }
  .faq-component-text2 {
    font-size: 26px;
    text-align: center;
  }
  .faq-component-text3 {
    font-size: 16px;
    text-align: center;
  }
}
@media(max-width: 479px) {
  .faq-component-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .faq-component-text {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
  }
  .faq-component-text1 {
    font-size: 14px;
  }
  .faq-component-list-item1 {
    align-items: center;
  }
  .faq-component-faq1-answer {
    text-align: center;
  }
  .faq-component-list-item2 {
    align-items: center;
  }
  .faq-component-faq2-answer {
    text-align: center;
  }
  .faq-component-list-item3 {
    align-items: center;
  }
  .faq-component-faq3-question {
    text-align: center;
  }
  .faq-component-faq3-answer {
    text-align: center;
  }
  .faq-component-list-item4 {
    align-items: center;
  }
  .faq-component-faq4-question {
    text-align: center;
  }
  .faq-component-faq4-answer {
    text-align: center;
  }
  .faq-component-list-item6 {
    align-items: center;
  }
  .faq-component-faq1-question1 {
    text-align: center;
  }
  .faq-component-faq1-answer1 {
    text-align: center;
  }
  .faq-component-list-item7 {
    align-items: center;
  }
  .faq-component-faq2-question1 {
    text-align: center;
  }
  .faq-component-faq2-answer1 {
    text-align: center;
  }
  .faq-component-list-item8 {
    align-items: center;
  }
  .faq-component-faq3-question1 {
    text-align: center;
  }
  .faq-component-faq3-answer1 {
    text-align: center;
  }
  .faq-component-list-item5 {
    align-items: center;
  }
  .faq-component-faq5-question {
    text-align: center;
  }
  .faq-component-faq5-answer {
    text-align: center;
  }
  .faq-component-content1 {
    align-items: center;
  }
  .faq-component-text2 {
    font-size: 22px;
    text-align: center;
  }
  .faq-component-text3 {
    font-size: 16px;
    text-align: center;
  }
}
</style>
