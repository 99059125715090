<template>
  <div class="contact-container">
    <app-navbar4 rootClassName="navbar4-root-class-name4"></app-navbar4>
    <div id="contactH" class="contact-contact5 thq-section-padding">
      <div class="contact-max-width thq-section-max-width thq-flex-row">
        <div class="contact-content thq-flex-column">
          <div class="contact-section-title">
            <h2 class="contact-text thq-heading-2">Contact Us</h2>
            <span class="contact-text1 thq-body-large">
              Have a question or need further assistance?
            </span>
            <span class="contact-text2 thq-body-small">
              Fill out the form and we&apos;ll get back to you shortly.
            </span>
          </div>
          <div class="contact-contact-info thq-flex-column">
            <div class="contact-row thq-flex-row">
              <svg viewBox="0 0 1024 1024" class="thq-icon-small">
                <path
                  d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"
                ></path>
              </svg>
              <span class="contact-email thq-body-small">
                Please use the form to email us
              </span>
            </div>
            <div class="contact-row1 thq-flex-row">
              <svg viewBox="0 0 1024 1024" class="thq-icon-small">
                <path
                  d="M282 460q96 186 282 282l94-94q20-20 44-10 72 24 152 24 18 0 30 12t12 30v150q0 18-12 30t-30 12q-300 0-513-213t-213-513q0-18 12-30t30-12h150q18 0 30 12t12 30q0 80 24 152 8 26-10 44z"
                ></path>
              </svg>
              <span class="contact-phone thq-body-small">
                +44 (0) 208 154 7034
              </span>
            </div>
            <div class="contact-row2 thq-flex-row">
              <svg viewBox="0 0 1024 1024" class="thq-icon-small">
                <path
                  d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"
                ></path>
              </svg>
              <span class="contact-address thq-body-small">
                Fourth Floor, 70 Baker Street, London, W1U 7DL
              </span>
            </div>
          </div>
        </div>
        <contact-form rootClassName="contact-form-root-class-name"></contact-form>
      </div>
    </div>
    <faq-component
      faq1Answer="Yubaro, based in London, provides a digital alternative asset platform for discerning collectors seeking personalized alternative asset sloutions."
      faq2Answer="We are a team of experienced professionals committed to offering you support and guidance as you select your next passion purchase. We are digitally focused and adapt to market trends to provide the best user experience."
      faq3Answer="Once you complete your onboarding on our app or website, you will be shown a range of offers currently available on the offers page, including whisky casks, commodities, art investments, and property lending. Once you choose your desired products, you can get an estimated projection of some investments, such as whisky casks and art investments.  After this, a Yubaro representative will contact you for an initial call. Once you are happy to proceed, we will arrange a call with you and the vendor to close the deal."
      faq4Answer="Our mission is to create a user-friendly platform where alternative assets can be purchased, and performances can be tracked. We aim to be leaders in the market of alternative assets and aspire to build the largest community around alternative assets in the near future."
      faq5Answer="We provide a nimble, quick, and easy-to-use application offering a range of the most attractive alternative assets available on the market today, along with simplified onboarding with our preferred vendors."
      faq6Answer="We work with a variety of vendors to offer a range of alternative assets for purchase. These can depend on the time of the year and availability. At the moment, we offer art, whisky, and property opportunities."
      faq7Answer="This will depend on the type of purchase you have made and the contract you have between the vendor and yourself. Early exit charges may apply. Please contact us using the links above and below, and we will be happy to help."
      faq8Answer="You can contact Yubaro via email at help@yubaro.io or via phone at +44 208 154 7034. Links to email and contact us are on the top and bottom of this page."
      faq1Question="What is Yubaro?"
      faq2Question="Why should I use Yubaro?"
      faq3Question="How does Yubaro work?"
      faq4Question="What is the company's mission?"
      faq5Question="What is Yubaro's business model?"
      faq6Question="What products do you offer?"
      faq7Question="Can I exit a purchase earlier then planned?"
      faq8Question="How do I contact Yubaro?"
    ></faq-component>
    <app-footer></app-footer>
    <cookie-consent-banner
      rootClassName="cookie-consent-banner-root-class-name3"
    ></cookie-consent-banner>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import ContactForm from '../components/contact-form'
import FaqComponent from '../components/faq-component'
import AppFooter from '../components/footer'
import CookieConsentBanner from '../components/cookie-consent-banner'

export default {
  name: 'Contact',
  props: {},
  components: {
    AppNavbar4,
    ContactForm,
    FaqComponent,
    AppFooter,
    CookieConsentBanner,
  },
  metaInfo: {
    title: 'Contact Us and FAQs - Yubaro',
    meta: [
      {
        name: 'description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        property: 'og:title',
        content: 'Contact Us and FAQs - Yubaro',
      },
      {
        property: 'og:description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
    ],
  },
}
</script>

<style scoped>
.contact-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #e0d5bb;
}
.contact-contact5 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.contact-max-width {
  align-items: flex-start;
}
.contact-content {
  width: 1522px;
  margin-right: var(--dl-space-space-threeunits);
}
.contact-section-title {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-text {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.contact-text1 {
  width: 100%;
  font-family: "Poppins";
}
.contact-text2 {
  font-family: "Poppins";
}
.contact-contact-info {
  gap: 16px;
  display: flex;
  padding: 8px 0;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-row {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-email {
  font-family: "Poppins";
}
.contact-row1 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-phone {
  font-family: "Poppins";
}
.contact-row2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.contact-address {
  font-family: "Poppins";
}
@media(max-width: 1600px) {
  .contact-content {
    width: 1313px;
  }
}
@media(max-width: 1200px) {
  .contact-content {
    gap: var(--dl-space-space-halfunit);
    width: 847px;
    padding-right: 0px;
  }
}
@media(max-width: 991px) {
  .contact-max-width {
    flex-direction: column;
  }
  .contact-content {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    align-items: center;
    margin-right: 0px;
    justify-content: center;
  }
  .contact-section-title {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    align-items: center;
  }
  .contact-text1 {
    width: auto;
    text-align: center;
  }
  .contact-contact-info {
    width: 100%;
    align-items: center;
  }
}
@media(max-width: 767px) {
  .contact-content {
    position: relative;
    align-items: center;
    justify-content: center;
  }
  .contact-section-title {
    align-items: center;
  }
  .contact-contact-info {
    align-items: center;
  }
  .contact-email {
    font-family: Poppins;
  }
}
@media(max-width: 479px) {
  .contact-section-title {
    align-items: center;
  }
  .contact-text {
    text-align: center;
  }
  .contact-text1 {
    text-align: center;
  }
  .contact-text2 {
    text-align: center;
  }
  .contact-contact-info {
    width: 100%;
    align-items: center;
  }
  .contact-email {
    text-align: left;
  }
  .contact-phone {
    text-align: center;
  }
  .contact-address {
    text-align: center;
  }
}
</style>
