<template>
  <div id="rowform" class="subscribe-form-form">
    <form
      id="newsletter-signup-form"
      name="newsletter-signup-form"
      class="subscribe-form-form1"
      @submit.prevent="submitForm"
    >
      <div class="subscribe-form-container">
        <input
          type="email"
          id="email"
          name="email"
          v-model="email"
          placeholder="Enter your email"
          autocomplete="email"
          class="subscribe-form-text-input thq-input"
        />
      </div>
      <button
        id="submitButton"
        type="submit"
        class="subscribe-form-button thq-button-outline"
      >
        <span class="subscribe-form-action1 thq-body-small">Subscribe</span>
      </button>

      <div class="subscribe-form-container1">
        <span class="subscribe-form-text">
          This site is protected by reCAPTCHA, and the Google 
        </span>
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer noopener"
          class="subscribe-form-link"
        >
          Privacy Policy
        </a>
        <span class="subscribe-form-text1"> and </span>
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noreferrer noopener"
          class="subscribe-form-link1"
        >
          Terms of Service
        </a>
        <span class="subscribe-form-text2"> apply</span>
      </div>

      <div
        class="g-recaptcha"
        :data-sitekey="recaptchaSiteKey"
        data-callback="onRecaptchaSuccess"
        data-size="invisible"
        ref="recaptcha"
      ></div>

      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'SubscribeForm',
  data() {
    return {
      email: '',
      recaptchaSiteKey: '6LfN2O8pAAAAAMOWqW9Nt5ykRACiVZH_nbUCRPfr',
      errorMessage: '' // New data property for error messages
    }
  },
  methods: {
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },
    submitForm() {
      this.errorMessage = ''

      if (!this.email) {
        this.errorMessage = 'Email address is required.'
        return
      }

      if (!this.validateEmail(this.email)) {
        this.errorMessage = 'Please enter a valid email address.'
        return
      }

      if (this.$refs.recaptcha) {
        grecaptcha.execute(this.$refs.recaptcha.dataset.sitekey, { action: 'submit' })
          .then(token => {
            this.onRecaptchaSuccess(token)
          })
          .catch(error => {
            console.error('reCAPTCHA execution error:', error)
            this.errorMessage = 'Failed to execute reCAPTCHA. Please try again.'
          })
      } else {
        console.error('reCAPTCHA ref not found')
        this.errorMessage = 'reCAPTCHA not loaded. Please try again later.'
      }
    },
    onRecaptchaSuccess(token) {
      const data = {
        email: this.email,
        recaptchaToken: token
      }

      fetch('https://yubaro-8539.twil.io/jugnu', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            this.$router.push('/newsletter-verify') // Change redirect URL
          } else {
            this.errorMessage = 'Failed to sign up: ' + (result.message || 'Unknown error')
          }
        })
        .catch(error => {
          console.error('Error:', error)
          this.errorMessage = 'An error occurred. Please try again later.'
        })
    }
  },
  mounted() {
    window.onRecaptchaSuccess = this.onRecaptchaSuccess
  }
}
</script>

<style scoped>
.subscribe-form-form {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: stretch;
  flex-shrink: 0;
  flex-direction: column;
}
.subscribe-form-form1 {
  gap: var(--dl-space-space-halfunit);
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.subscribe-form-container {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  height: 45px;
  display: flex;
  align-items: flex-start;
}
.subscribe-form-text-input {
  gap: 8px;
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  flex: 1;
  color: var(--dl-color-yubarobrandcolours-primarytext);
  width: 100%;
  height: 36px;
  display: flex;
  padding: 0px;
  font-size: 16px;
  box-sizing: content-box;
  text-align: left;
  align-items: center;
  font-family: Roboto;
  font-weight: 400;
  border-color: var(--dl-color-yubarobrandcolours-primary);
  background-color: transparent;
}
.subscribe-form-button {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  width: 100%;
  height: 36px;
  margin-left: 0px;
  border-color: var(--dl-color-yubarobrandcolours-secondary);
}
.subscribe-form-action1 {
  fill: var(--dl-color-yubarobrandcolours-secondary);
  color: var(--dl-color-yubarobrandcolours-secondary);
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.subscribe-form-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
.subscribe-form-text {
  font-size: 10px;
  text-align: center;
}
.subscribe-form-link {
  color: var(--dl-color-yubarobrandcolours-primary);
  font-size: 10px;
  text-align: center;
  text-decoration: underline;
}
.subscribe-form-text1 {
  font-size: 10px;
  text-align: center;
}
.subscribe-form-link1 {
  fill: var(--dl-color-yubarobrandcolours-primary);
  color: var(--dl-color-yubarobrandcolours-primary);
  font-size: 10px;
  text-align: center;
  text-decoration: underline;
}
.subscribe-form-text2 {
  font-size: 10px;
  text-align: center;
}
.error-message {
  color: red;
  margin-top: 10px;
}
@media(max-width: 1200px) {
  .subscribe-form-form1 {
    gap: var(--dl-space-space-unit);
  }
  .subscribe-form-button {
    width: 100%;
    margin-left: 0px;
  }
}
@media(max-width: 991px) {
  .subscribe-form-form {
    width: 100%;
    flex-direction: column;
  }
  .subscribe-form-form1 {
    width: 100%;
  }
  .subscribe-form-container {
    width: 100%;
    height: auto;
  }
  .subscribe-form-text-input {
    flex: 1;
    width: 263px;
    height: 26px;
    padding: var(--dl-space-space-halfunit);
  }
  .subscribe-form-button {
    flex: 1;
    width: 100%;
    height: 26px;
    margin-left: 0px;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 767px) {
  .subscribe-form-form {
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
  .subscribe-form-form1 {
    width: 100%;
    height: 128px;
    align-items: center;
  }
  .subscribe-form-container {
    width: 100%;
  }
  .subscribe-form-text-input {
    flex: 4;
  }
  .subscribe-form-button {
    width: 100%;
    height: var(--dl-size-size-medium);
  }
}
@media(max-width: 479px) {
  .subscribe-form-form {
    width: 100%;
    flex-direction: column;
  }
  .subscribe-form-form1 {
    height: auto;
  }
  .subscribe-form-container {
    width: 100%;
  }
  .subscribe-form-text-input {
    flex: 2;
  }
  .subscribe-form-button {
    flex: 1;
    width: 100%;
  }
  .subscribe-form-container1 {
    flex-wrap: wrap;
  }

}
</style>
