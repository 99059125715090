import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Home from './views/home'
import PrivacyPolicy from './views/privacy-policy'
import AboutUs from './views/about-us'
import Download from './views/download'
import Page from './views/page'
import Newsletter from './views/newsletter'
import TermsAndConditions from './views/terms-and-conditions'
import Page1 from './views/page1'
import NewsletterVerify from './views/newsletter-verify'
import ViewCookies from './views/view-cookies'
import Contact from './views/contact'
import CapitalRiskDisclosure from './views/capital-risk-disclosure'
import NotFound from './views/not-found'
import './style.css'

Vue.use(Router)
Vue.use(Meta)

export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'Home',
      path: '/',
      component: Home,
      meta: { scrollToTop: true },
    },
    {
      name: 'PrivacyPolicy',
      path: '/privacy-policy',
      component: PrivacyPolicy,
      meta: { scrollToTop: true },
    },
    {
      name: 'About-Us',
      path: '/about-us',
      component: AboutUs,
      meta: { scrollToTop: true },
    },
    {
      name: 'Download',
      path: '/download',
      component: Download,
      meta: { scrollToTop: true },
    },
    {
      name: 'Newsletter',
      path: '/newsletter-added',
      component: Newsletter,
      meta: { scrollToTop: true },
    },
    {
      name: 'TermsAndConditions',
      path: '/terms-and-conditions',
      component: TermsAndConditions,
      meta: { scrollToTop: true },
    },
    {
      name: 'Page1',
      path: '/500',
      component: Page1,
	  meta: { scrollToTop: true },
    },
    {
      name: 'Newsletter-Verify',
      path: '/newsletter-verify',
      component: NewsletterVerify,
	  meta: { scrollToTop: true },
    },
    {
      name: 'ViewCookies',
      path: '/view-cookies',
      component: ViewCookies,
      meta: { scrollToTop: true },
    },
    {
      name: 'Contact',
      path: '/contact',
      component: Contact,
      meta: { scrollToTop: true },
    },
    {
      name: 'Capital-Risk-Disclosure',
      path: '/capital-risk-disclosure',
      component: CapitalRiskDisclosure,
      meta: { scrollToTop: true },
    },
    {
      name: 'Page1',
      path: '/not-found',
      component: Page1,
	  meta: { scrollToTop: true },
    },
    {
      name: 'Newsletter-Verify',
      path: '/newsletter-verify',
      component: NewsletterVerify,
	  meta: { scrollToTop: true },
    },
    {
      name: '404 - Not Found',
      path: '*',
      component: Page1,
      fallback: true,
      meta: { scrollToTop: true },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.meta.scrollToTop) {
      return { x: 0, y: 0 }
    } else if (savedPosition) {
      return savedPosition
    }
    return {}
  },
})
