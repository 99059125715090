<template>
  <div class="page-container">
    <app-navbar4 rootClassName="navbar4-root-class-name14"></app-navbar4>
    <div class="page-header76">
      <div class="page-max-width thq-section-max-width">
        <div class="page-column thq-section-padding">
          <img alt="image" src="/yubaro-slash-200w.webp" class="page-image" />
          <h1 id="aboutH" class="page-text thq-heading-1">
            <span class="page-text01">Error 404 - Page Not Found</span>
            <br />
          </h1>
          <p class="page-text03 thq-body-large">
            <span>
              We are sorry, it appears you have visited a page that does not exist
              on our website.
            </span>
            <br />
          </p>
          <router-link to="/" class="page-navlink thq-button-filled button">
            Click here to return to our homepage
          </router-link>
          <p class="page-text06 thq-body-large">
            <span>
              If you keep receiving this error, please
              <span v-html="raw17s2"></span>
            </span>
            <span class="page-text08">click here</span>
            <span>to contact us.</span>
            <br />
          </p>
        </div>
      </div>
      <about-image-section
        rootClassName="about-image-section-root-class-name2"
      ></about-image-section>
    </div>
    <contact-us-bottom></contact-us-bottom>
    <app-footer></app-footer>
    <cookie-consent-banner
      rootClassName="cookie-consent-banner-root-class-name10"
    ></cookie-consent-banner>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AboutImageSection from '../components/about-image-section'
import ContactUsBottom from '../components/contact-us-bottom'
import AppFooter from '../components/footer'
import CookieConsentBanner from '../components/cookie-consent-banner'

export default {
  name: 'Page',
  props: {},
  components: {
    AppNavbar4,
    AboutImageSection,
    ContactUsBottom,
    AppFooter,
    CookieConsentBanner,
  },
  data() {
    return {
      raw17s2: ' ',
    }
  },
  metaInfo: {
    title: 'Error 404 - Page Not Found - Yubaro',
    meta: [
      {
        name: 'description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        property: 'og:title',
        content: 'Error 404 - Page Not Found - Yubaro',
      },
      {
        property: 'og:description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  },
}
</script>

<style scoped>
.page-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #e0d5bb;
}
.page-header76 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
}
.page-max-width {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.page-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.page-image {
  top: 48px;
  left: 30px;
  width: 95px;
  height: 97px;
  z-index: 0;
  position: absolute;
  object-fit: cover;
}
.page-text {
  z-index: 100;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 0.8!important;
}
.page-text03 {
  font-family: "Poppins";
}
.page-navlink {
  text-decoration: none;
}
.page-text06 {
  font-family: "Poppins";
}
@media(max-width: 1600px) {
  .page-text {
    font-family: Poppins;
  }
  .page-text01 {
    font-size: 32px;
  }
  .page-text03 {
    font-family: Poppins;
  }
  .page-navlink {
    font-style: normal;
    font-weight: 400;
  }
  .page-text06 {
    font-family: Poppins;
  }
  .page-text08 {
    text-decoration: underline;
  }
}
@media(max-width: 1200px) {
  .page-image {
    top: 47px;
    left: 33px;
  }
}
@media(max-width: 991px) {
  .page-header76 {
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .page-max-width {
    flex-direction: column;
  }
  .page-column {
    width: 100%;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
}
@media(max-width: 767px) {
  .page-column {
    position: relative;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .page-image {
    top: 30px;
    left: 16px;
    width: 61px;
    height: 62px;
    position: absolute;
    align-self: flex-end;
  }
  .page-text {
    width: 100%;
    font-style: normal;
    text-align: left;
    font-family: Poppins;
    font-weight: 500;
  }
  .page-text03 {
    text-align: left;
  }
  .page-text06 {
    text-align: left;
  }
}
@media(max-width: 479px) {
  .page-column {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .page-image {
    top: 9px;
    left: 2px;
    width: 49px;
    height: 49px;
  }
  .page-text {
    text-align: left;
  }
  .page-text03 {
    text-align: left;
  }
  .page-text06 {
    text-align: left;
  }
}
</style>
