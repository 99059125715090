<template>
  <div v-if="showBanner" class="cookie-consent-banner-container" v-bind:class="rootClassName">
    <div class="cookie-consent-banner-container1">
      <div class="cookie-consent-banner-container2">
        <span class="cookie-consent-banner-text">
          We use cookies to improve your experience on our site.
        </span>
        <router-link to="/view-cookies" class="cookie-consent-banner-navlink">
          Click here to read our Cookie Policy
        </router-link>
      </div>
      <button @click="acceptCookies" type="button" class="cookie-consent-banner-button button">
        I accept
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookieConsentBanner',
  props: {
    rootClassName: String,
  },
  data() {
    return {
      showBanner: false,
    };
  },
  methods: {
    acceptCookies() {
      this.setCookie('cookiesAccepted', true, 365);
      this.showBanner = false;
    },
    setCookie(name, value, days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = "expires=" + date.toUTCString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
    },
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
  },
  created() {
    if (!this.getCookie('cookiesAccepted')) {
      this.showBanner = true;
    }
  }
};
</script>

<style scoped>
.cookie-consent-banner-container {
  width: 100%;
  bottom: 0;
  height: auto;
  display: flex;
  position: fixed;
  text-align: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-yubarobrandcolours-primarytext);
}
.cookie-consent-banner-container1 {
  gap: 0;
  width: 100%;
  height: 46px;
  display: flex;
  z-index: 200;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-yubarobrandcolours-primarytext);
}
.cookie-consent-banner-container2 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.cookie-consent-banner-text {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  font-size: 12px;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-halfunit);
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
}
.cookie-consent-banner-navlink {
  fill: var(--dl-color-yubarobrandcolours-primary);
  color: var(--dl-color-yubarobrandcolours-primary);
  padding: var(--dl-space-space-halfunit);
  font-size: 12px;
  text-decoration: none;
}
.cookie-consent-banner-button {
  padding: 5px;
  font-size: 12px;
  margin-left: var(--dl-space-space-oneandhalfunits);
}
@media(max-width: 767px) {
  .cookie-consent-banner-container2 {
    width: auto;
    flex-direction: column;
  }
  .cookie-consent-banner-text {
    padding-left: 0px;
    padding-bottom: 0px;
  }
  .cookie-consent-banner-navlink {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 479px) {
  .cookie-consent-banner-container {
    height: 44px;
  }
  .cookie-consent-banner-container1 {
    align-items: center;
    justify-content: center;
  }
  .cookie-consent-banner-text {
    font-size: 10px;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
  .cookie-consent-banner-navlink {
    font-size: 10px;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
  .cookie-consent-banner-button {
    font-size: 10px;
    align-self: center;
    margin-left: var(--dl-space-space-unit);
  }
}
</style>
