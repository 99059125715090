<template>
  <div
    class="app-features-layout251 thq-section-padding"
    v-bind:class="rootClassName"
  >
    <div class="app-features-max-width thq-section-max-width">
      <div class="app-features-section-title thq-flex-row">
        <div class="app-features-column thq-flex-column">
          <h2 class="app-features-text thq-heading-2">{{ sectionTitle }}</h2>
          <span class="app-features-text01 thq-body-small">{{ slogan }}</span>
        </div>
      </div>
      <div class="app-features-content">
        <div class="app-features-row thq-flex-row">
          <div class="app-features-feature1 thq-flex-column">
            <div class="app-features-feature1-image thq-img-ratio-4-3">
              <img
                :alt="imageAlt2"
                :src="imageSrc2"
                fetchpriority="high"
                class="app-features-image thq-img-scale thq-img-ratio-4-3"
              />
            </div>
            <div class="app-features-content1 thq-flex-column">
              <h3 class="app-features-feature1-title thq-heading-3">
                {{ feature1Title }}
              </h3>
              <span class="app-features-feature1-description thq-body-small">
                <span>
                  Explore a vast selection of alternative assets, from
                  <span v-html="rawdgzu"></span>
                </span>
                <span class="app-features-text03">fine art</span>
                <span>
                  and
                  <span v-html="rawsqd6"></span>
                </span>
                <span class="app-features-text05">classic cars</span>
                <span>
                  to
                  <span v-html="rawialx"></span>
                </span>
                <span class="app-features-text07">precious metals</span>
                <span>
                  ,
                  <span v-html="raw5ap0"></span>
                </span>
                <span class="app-features-text09">whiskey casks</span>
                <span>
                  ,
                  <span v-html="rawu4ka"></span>
                </span>
                <span class="app-features-text11">property</span>
                <span>
                  , and
                  <span v-html="rawqmdc"></span>
                </span>
                <span class="app-features-text13">fine wines</span>
                <span>
                  . Our expertly curated marketplace ensures you find unique items
                  to diversify and strengthen your portfolio.
                </span>
              </span>
            </div>
          </div>
          <div class="app-features-feature2 thq-flex-column">
            <div class="app-features-feature2-image thq-img-ratio-4-3">
              <img
                :alt="imageAlt3"
                :src="imageSrc3"
                class="app-features-image1 thq-img-ratio-4-3"
              />
            </div>
            <div class="app-features-content2 thq-flex-column">
              <h3 class="app-features-feature2-title thq-heading-3">
                {{ feature2Title }}
              </h3>
              <span class="app-features-feature2-description thq-body-small">
                {{ feature2Description }}
              </span>
            </div>
          </div>
          <div class="app-features-feature3 thq-flex-column">
            <div class="app-features-feature3-image thq-img-ratio-4-3">
              <img
                :alt="imageAlt1"
                :src="imageSrc1"
                class="app-features-image2 thq-img-ratio-4-3"
              />
            </div>
            <div class="app-features-content3 thq-flex-column">
              <h3 class="app-features-feature3-title thq-heading-3">
                {{ feature3Title }}
              </h3>
              <span class="app-features-feature3-description thq-body-small">
                {{ feature3Description }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="app-features-actions">
        <a
          href="https://apps.apple.com/us/app/yubaro/id6502051801"
          target="_blank"
          rel="noreferrer noopener"
          class="app-features-button thq-button-filled"
        >
          <span class="app-features-action1 thq-body-small">
            <span>Download on the Apple App Store</span>
            <br />
          </span>
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.yubaro.clientapp"
          target="_blank"
          rel="noreferrer noopener"
          class="app-features-button1 thq-button-filled"
        >
          <span class="app-features-action11 thq-body-small">
            <span>Download on the Google Play Store</span>
            <br />
          </span>
        </a>
        <a
          href="https://app.yubaro.io/"
          target="_blank"
          rel="noreferrer noopener"
          class="app-features-button2 thq-button-outline"
        >
          <span class="app-features-action2 thq-body-small">
            Access it on the web
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFeatures',
  props: {
    feature1Title: {
      type: String,
      default: 'Diverse Asset Portfolio',
    },
    imageSrc2: {
      type: String,
      default: '/features/offer-page-1400w.webp',
    },
    sectionTitle: {
      type: String,
      default: 'Features',
    },
    imageAlt1: {
      type: String,
      default: 'App showing the News Page',
    },
    imageSrc3: {
      type: String,
      default: '/features/portfolio-page-1400w.webp',
    },
    feature2Title: {
      type: String,
      default: 'Track Your Purchases',
    },
    feature2Description: {
      type: String,
      default:
        'Monitor your purchases effortlessly with our real-time valuation tracking. Stay informed on the current market value of your assets, helping you make well-informed decisions and maximize your returns.',
    },
    imageAlt2: {
      type: String,
      default: 'App showing the current offers',
    },
    feature3Title: {
      type: String,
      default: 'Expert Insights & News',
    },
    imageSrc1: {
      type: String,
      default: '/features/news-page-1400w.webp',
    },
    feature3Description: {
      type: String,
      default:
        'Stay ahead with the latest market trends and expert insights. Our platform provides you with up-to-date news, analysis, and advice, ensuring you make informed investment choices and stay connected with the alternative asset market.',
    },
    imageAlt3: {
      type: String,
      default: 'App showing the portfolio page',
    },
    slogan: {
      type: String,
      default: 'Empowering your portfolio decisions.',
    },
    rootClassName: String,
  },
  data() {
    return {
      rawdgzu: ' ',
      rawsqd6: ' ',
      rawialx: ' ',
      raw5ap0: ' ',
      rawu4ka: ' ',
      rawqmdc: ' ',
    }
  },
}
</script>

<style scoped>
.app-features-layout251 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.app-features-max-width {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-direction: column;
}
.app-features-section-title {
  align-self: center;
  justify-content: center;
}
.app-features-column {
  gap: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-shrink: 0;
}
.app-features-text {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  align-self: center;
  font-family: "Poppins";
}
.app-features-text01 {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  align-self: center;
  font-family: "Poppins";
}
.app-features-content {
  gap: 48px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.app-features-row {
  align-items: flex-start;
}
.app-features-feature1 {
  flex: 1;
}
.app-features-feature1-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-features-image {
  width: 100%;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  object-fit: cover;
}
.app-features-content1 {
  gap: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: flex-start;
}
.app-features-feature1-title {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  align-self: center;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.app-features-feature1-description {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  text-align: center;
  font-family: "Poppins";
}
.app-features-text03 {
  font-weight: 700;
}
.app-features-text05 {
  font-weight: 700;
}
.app-features-text07 {
  font-weight: 700;
}
.app-features-text09 {
  font-weight: 700;
}
.app-features-text11 {
  font-weight: 700;
}
.app-features-text13 {
  font-weight: 700;
}
.app-features-feature2 {
  flex: 1;
}
.app-features-feature2-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-features-image1 {
  width: 100%;
  object-fit: cover;
}
.app-features-content2 {
  gap: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: flex-start;
}
.app-features-feature2-title {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  align-self: center;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.app-features-feature2-description {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  text-align: center;
  font-family: "Poppins";
}
.app-features-feature3 {
  flex: 1;
}
.app-features-feature3-image {
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('https://play.teleporthq.io/static/svg/default-img.svg');
  background-position: center;
}
.app-features-image2 {
  width: 100%;
  object-fit: cover;
  margin-left: 3px;
}
.app-features-content3 {
  gap: var(--dl-space-space-halfunit);
  align-self: center;
  align-items: flex-start;
}
.app-features-feature3-title {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  align-self: center;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.app-features-feature3-description {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  text-align: center;
  font-family: "Poppins";
}
.app-features-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  justify-content: center;
}
.app-features-button {
  text-decoration: none;
}
.app-features-action1 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
}
.app-features-button1 {
  text-decoration: none;
}
.app-features-action11 {
  fill: var(--dl-color-yubarobrandcolours-white);
  color: var(--dl-color-yubarobrandcolours-white);
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
}
.app-features-button2 {
  text-decoration: none;
}
.app-features-action2 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
}

@media(max-width: 1600px) {
  .app-features-feature1-image {
    width: 100%;
    height: 311px;
  }
  .app-features-image {
    height: 311px;
  }
  .app-features-feature2-image {
    width: 100%;
    height: 311px;
  }
  .app-features-image1 {
    height: 311px;
  }
  .app-features-feature3-image {
    width: 100%;
    height: 311px;
  }
  .app-features-image2 {
    width: 100%;
    height: 100%;
  }
}
@media(max-width: 1200px) {
  .app-features-row {
    width: 100%;
  }
}
@media(max-width: 991px) {
  .app-features-section-title {
    align-items: flex-start;
    flex-direction: column;
  }
  .app-features-row {
    width: 100%;
  }
  .app-features-feature1-image {
    height: 311px;
  }
  .app-features-feature1-title {
    text-align: center;
  }
  .app-features-feature2-image {
    height: 311px;
  }
  .app-features-feature2-title {
    text-align: center;
  }
  .app-features-feature3-image {
    height: 311px;
  }
  .app-features-feature3-title {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .app-features-column {
    width: 100%;
  }
  .app-features-text {
    text-align: center;
  }
  .app-features-row {
    flex-direction: column;
  }
  .app-features-feature1 {
    width: 100%;
  }
  .app-features-feature1-image {
    width: 100%;
    height: 311px;
  }
  .app-features-image {
    height: 311px;
  }
  .app-features-feature2 {
    width: 100%;
  }
  .app-features-feature2-image {
    width: 100%;
    height: 311px;
    padding-bottom: 0px;
  }
  .app-features-image1 {
    height: 311px;
  }
  .app-features-feature3 {
    width: 100%;
  }
  .app-features-feature3-image {
    width: 100%;
    height: 311px;
    padding-bottom: 0px;
  }
  .app-features-image2 {
    width: 710px;
    height: 311px;
    padding-bottom: 0px;
  }
  .app-features-actions {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .app-features-row {
    width: 100%;
  }
  .app-features-feature1 {
    width: 100%;
  }
  .app-features-feature1-image {
    height: 311px;
  }
  .app-features-image {
    width: 100%;
    height: 311px;
  }
  .app-features-feature1-description {
    width: 100%;
  }
  .app-features-feature2 {
    width: 100%;
  }
  .app-features-feature2-image {
    height: 311px;
  }
  .app-features-feature3 {
    width: 100%;
  }
  .app-features-feature3-image {
    width: 100%;
    height: 311px;
  }
  .app-features-image2 {
    width: 100%;
    height: 311px;
    margin-right: 0px;
  }
  .app-features-actions {
    width: 100%;
    flex-direction: column;
  }
  .app-features-button {
    width: 100%;
  }
  .app-features-button1 {
    width: 100%;
  }
  .app-features-button2 {
    width: 100%;
  }
}
</style>
