<template>
  <div class="about-us-container1">
    <app-navbar4 rootClassName="navbar4root-class-name2"></app-navbar4>
    <div class="about-us-header76">
      <div class="about-us-max-width1 thq-section-max-width">
        <div class="about-us-column1 thq-section-padding">
          <img alt="image" src="/yubaro-slash-200w.webp" class="about-us-image" />
          <h1 id="aboutH" class="about-us-text10 thq-heading-1">
            <span class="about-us-text11">Unlocking wealth,</span>
            <br class="about-us-text12" />
            <span class="about-us-text13">embracing possibilities</span>
            <br />
          </h1>
          <p class="about-us-text15 thq-body-large">
            <span>
              Welcome to Yubaro, your trusted partner in the world of alternative
              assets. Founded with a vision to democratize access to high-value
              assets, Yubaro brings together a community of passionate collectors
              and knowledgeable vendors to create a vibrant marketplace for unique
              and lucrative opportunities.
            </span>
            <br />
            <br />
            <span>
              Our mission is to provide a seamless and secure platform where you
              can explore, acquire, and grow your wealth through diverse asset
              classes. From rare whiskey casks and fine art to classic cars,
              luxury watches, and precious metals, Yubaro offers a curated
              selection of high-quality assets to suit every collector&apos;s
              portfolio.
            </span>
            <br />
            <br />
            <span>
              At Yubaro, we believe in the power of informed decisions. Our team
              of experts is dedicated to providing you with the latest market
              insights, trends, and analysis to help you make well-informed
              choices. We strive to ensure that your experience with us is not
              only profitable but also enriching and enjoyable.
            </span>
            <br />
            <br />
            <span>
              Join us on this exciting journey of discovering new possibilities
              and unlocking wealth through alternative assets. Whether you are a
              seasoned collector or just starting, Yubaro is here to guide you
              every step of the way.
            </span>
          </p>
        </div>
        <div class="about-us-content1">
          <div class="about-us-column2">
            <img
              alt="Hero Image"
              srcset="/about-us/photos//dan-burton-sdogdunfcjo-unsplash-1400w.webp 1200w, /about-us/photos/dan-burton-sdogdunfcjo-unsplash-tablet.webp 800w, /about-us/photos/dan-burton-sdogdunfcjo-unsplash-mobile.webp 480w"
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/about-us/photos/dan-burton-sdogdunfcjo-unsplash-1400w.webp"
              class="about-us-placeholder-image10 thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              srcset="/about-us/photos//marvin-l-61szczl3mlk-unsplash-1400w.webp 1200w, /about-us/photos/marvin-l-61szczl3mlk-unsplash-tablet.webp 800w, /about-us/photos/marvin-l-61szczl3mlk-unsplash-mobile.webp 480w"
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/about-us/photos/marvin-l-61szczl3mlk-unsplash-1400w.webp"
              class="about-us-placeholder-image11 thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              srcset="/about-us/photos//martin-pechy-r7-ccjcro48-unsplash-1400w.webp 1200w, /about-us/photos/martin-pechy-r7-ccjcro48-unsplash-tablet.webp 800w, /about-us/photos/martin-pechy-r7-ccjcro48-unsplash-mobile.webp 480w"
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/about-us/photos/martin-pechy-r7-ccjcro48-unsplash-1400w.webp"
              class="about-us-placeholder-image12 thq-img-ratio-1-1"
            />
          </div>
          <div class="about-us-column3">
            <img
              alt="Hero Image"
              srcset="/about-us/photos//trevor-gerzen-fdfwkzw0hf4-unsplash-1400w.webp 1200w, /about-us/photos/trevor-gerzen-fdfwkzw0hf4-unsplash-tablet.webp 800w, /about-us/photos/trevor-gerzen-fdfwkzw0hf4-unsplash-mobile.webp 480w"
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/about-us/photos/trevor-gerzen-fdfwkzw0hf4-unsplash-1400w.webp"
              class="about-us-placeholder-image13 thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              srcset="/about-us/photos//erol-ahmed-xsq8nukpao4-unsplash-1400w.webp 1200w, /about-us/photos/erol-ahmed-xsq8nukpao4-unsplash-tablet.webp 800w, /about-us/photos/erol-ahmed-xsq8nukpao4-unsplash-mobile.webp 480w"
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/about-us/photos/erol-ahmed-xsq8nukpao4-unsplash-1400w.webp"
              class="about-us-placeholder-image14 thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              srcset="/about-us/photos//fawazlul-rizqi-jkud3suxcmw-unsplash-1400w.webp 1200w, /about-us/photos/fawazlul-rizqi-jkud3suxcmw-unsplash-tablet.webp 800w, /about-us/photos/fawazlul-rizqi-jkud3suxcmw-unsplash-mobile.webp 480w"
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/about-us/photos/fawazlul-rizqi-jkud3suxcmw-unsplash-1400w.webp"
              class="about-us-placeholder-image15 thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              srcset="/about-us/photos//kent-lam-y0rrb835wjg-unsplash-1400w.webp 1200w, /about-us/photos/kent-lam-y0rrb835wjg-unsplash-tablet.webp 800w, /about-us/photos/kent-lam-y0rrb835wjg-unsplash-mobile.webp 480w"
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/about-us/photos/kent-lam-y0rrb835wjg-unsplash-1400w.webp"
              class="about-us-placeholder-image16 thq-img-ratio-1-1"
            />
          </div>
        </div>
      </div>
    </div>
    <app-features rootClassName="app-featuresroot-class-name"></app-features>
    <div class="about-us-team1 thq-section-padding">
      <div class="about-us-max-width2 thq-section-max-width">
        <div class="about-us-list-item10"></div>
        <div class="about-us-section-title">
          <div class="about-us-content2">
            <h2 class="about-us-text26 thq-heading-2">Meet our team</h2>
            <p class="about-us-text27 thq-body-large">
              Passionate Experts Driving your Success
            </p>
          </div>
        </div>
        <div class="about-us-content3">
          <div class="about-us-content4">
            <div class="about-us-row">
              <div class="about-us-container2">
                <div class="about-us-card1">
                  <img
                    alt="Arya Taware Photo"
                    src="/about-us/aryataware-500w.webp"
                    class="about-us-placeholder-image17 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div class="about-us-content5">
                    <div class="about-us-title1">
                      <span class="about-us-text28 thq-body-small">
                        Arya Taware
                      </span>
                      <span class="about-us-text29 thq-body-small">Director</span>
                    </div>
                    <span class="about-us-text30 thq-body-small">
                      Arya, a Forbes 30 under 30 Europe honoree, exited her
                      Fintech lending platform after building a £12.5M loan book.
                      She&apos;s now a consultant at Yubaro, advising on vendor
                      procurement, sales, and marketing.
                    </span>
                  </div>
                  <div class="about-us-social-icons1">
                    <a
                      href="https://www.linkedin.com/in/aryatawarefuturebricks/"
                      target="_blank"
                      rel="noreferrer noopener"
                      class="about-us-link1"
                    >
                      <svg
                        viewBox="0 0 877.7142857142857 1024"
                        class="about-us-icon1 thq-icon-small"
                      >
                        <path
                          d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="about-us-card2">
                  <img
                    alt="Rahul Mulchandani Photo"
                    src="/about-us/rahulmulchandani-500w.webp"
                    class="about-us-placeholder-image18 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div class="about-us-content6">
                    <div class="about-us-title2">
                      <span class="about-us-text31 thq-body-small">
                        Rahul Mulchandani
                      </span>
                      <span class="about-us-text32 thq-body-small">Director</span>
                    </div>
                    <span class="about-us-text33 thq-body-small">
                      Rahul is a seasoned entrepreneur with extensive operations
                      experience. He founded a successful online alcohol retail
                      store and, in 2014, established a craft brewery in London.
                      Rahul brings over a decade of startup operations expertise
                      to the team.
                    </span>
                  </div>
                  <div class="about-us-social-icons2">
                    <a
                      href="https://www.linkedin.com/in/rahul-mulchandani-51818410b/"
                      target="_blank"
                      rel="noreferrer noopener"
                      class="about-us-link2"
                    >
                      <svg
                        viewBox="0 0 877.7142857142857 1024"
                        class="about-us-icon3 thq-icon-small"
                      >
                        <path
                          d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="about-us-container3">
                <div class="about-us-card3">
                  <img
                    alt="Komal Arcot Picture"
                    src="/about-us/komalarcot1-500w.webp"
                    class="about-us-placeholder-image19 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div class="about-us-content7">
                    <div class="about-us-title3">
                      <span class="about-us-text34 thq-body-small">
                        Komal Arcot
                      </span>
                      <span class="about-us-text35 thq-body-small">
                        Relationship Manager
                      </span>
                    </div>
                    <span class="about-us-text36 thq-body-small">
                      Komal is a dedicated relationship manager, with years of
                      experience in customer service and management. At Yubaro,
                      she serves as a key contact for clients and acts as their
                      liaison with our vendors.
                    </span>
                  </div>
                  <div class="about-us-social-icons3">
                    <a
                      href="https://www.linkedin.com/in/komal-arcot-8b7711103/"
                      target="_blank"
                      rel="noreferrer noopener"
                      class="about-us-link3"
                    >
                      <svg
                        viewBox="0 0 877.7142857142857 1024"
                        class="about-us-icon5 thq-icon-small"
                      >
                        <path
                          d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="about-us-card4">
                  <img
                    alt="Consultant Picture"
                    src="/about-us/shadaantabani-500w.webp"
                    class="about-us-placeholder-image20 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div class="about-us-content8">
                    <div class="about-us-title4">
                      <span class="about-us-text37 thq-body-small">
                        Shadaan Tabani
                      </span>
                      <span class="about-us-text38 thq-body-small">
                        Consultant, Technology
                      </span>
                    </div>
                    <span class="about-us-text39 thq-body-small">
                      Shadaan is a seasoned technology expert with over two
                      decades of experience in providing customized tech solutions
                      for startups and entrepreneurial businesses. He excels in
                      driving innovation and growth through tailored technology
                      strategies.
                    </span>
                  </div>
                  <div class="about-us-social-icons4">
                    <a
                      href="https://www.linkedin.com/in/s-tabani/"
                      target="_blank"
                      rel="noreferrer noopener"
                      class="about-us-link4"
                    >
                      <svg
                        viewBox="0 0 877.7142857142857 1024"
                        class="about-us-icon7 thq-icon-small"
                      >
                        <path
                          d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <contact-us-bottom></contact-us-bottom>
    <app-footer></app-footer>
    <cookie-consent-banner
      rootClassName="cookie-consent-bannerroot-class-name1"
    ></cookie-consent-banner>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AppFeatures from '../components/app-features'
import ContactUsBottom from '../components/contact-us-bottom'
import AppFooter from '../components/footer'
import CookieConsentBanner from '../components/cookie-consent-banner'

export default {
  name: 'AboutUs',
  props: {},
  components: {
    AppNavbar4,
    AppFeatures,
    ContactUsBottom,
    AppFooter,
    CookieConsentBanner,
  },
  metaInfo: {
    title: 'About Us - Yubaro',
    meta: [
      {
        name: 'description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        property: 'og:title',
        content: 'About Us - Yubaro',
      },
      {
        property: 'og:description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
    ],
  },
}
</script>

<style scoped>
.about-us-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #e0d5bb;
}
.about-us-header76 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
}
.about-us-max-width1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.about-us-column1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.about-us-image {
  top: 48px;
  left: 30px;
  width: 95px;
  height: 97px;
  z-index: 0;
  position: absolute;
  object-fit: cover;
}
.about-us-text10 {
  z-index: 100;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 0.8!important;
}
.about-us-text11 {
  font-size: 32px;
}
.about-us-text13 {
  font-size: 32px;
}
.about-us-text15 {
  font-family: "Poppins";
}
.about-us-content1 {
  gap: var(--dl-space-space-unit);
  width: 50%;
  height: 900px;
  display: flex;
  position: relative;
  align-items: center;
}
.about-us-column2 {
  gap: 16px;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.about-us-placeholder-image10 {
  width: 100%;
  height: 400px;
  align-self: stretch;
  border-radius: var(--dl-radius-radius-radius4);
}
.about-us-placeholder-image11 {
  width: 100%;
  height: 400px;
  align-self: stretch;
  border-radius: var(--dl-radius-radius-radius4);
}
.about-us-placeholder-image12 {
  width: 100%;
  height: 400px;
  align-self: stretch;
  border-radius: var(--dl-radius-radius-radius4);
}
.about-us-column3 {
  gap: 16px;
  width: 50%;
  height: auto;
  display: flex;
  z-index: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.about-us-placeholder-image13 {
  width: 100%;
  height: 400px;
  align-self: stretch;
  border-radius: var(--dl-radius-radius-radius4);
}
.about-us-placeholder-image14 {
  width: 100%;
  height: 400px;
  align-self: stretch;
  border-radius: var(--dl-radius-radius-radius4);
}
.about-us-placeholder-image15 {
  width: 100%;
  height: 400px;
  align-self: stretch;
  border-radius: var(--dl-radius-radius-radius4);
}
.about-us-placeholder-image16 {
  width: 100%;
  height: 400px;
  align-self: stretch;
  border-radius: var(--dl-radius-radius-radius4);
}
.about-us-team1 {
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.about-us-max-width2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.about-us-list-item10 {
  gap: var(--dl-space-space-unit);
  height: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-imageradius);
  margin-bottom: 0px;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgba(163,89,27,1) 0%, rgba(241,184,96,1) 58%, rgba(244,188,98,1) 74%, rgba(199,130,96,1) 100%);;
}
.about-us-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.about-us-content2 {
  gap: 0;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.about-us-text26 {
  font-family: "Poppins";
}
.about-us-text27 {
  font-family: "Poppins";
}
.about-us-content3 {
  gap: var(--dl-space-space-sixunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.about-us-content4 {
  gap: 64px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.about-us-row {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}
.about-us-container2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.about-us-card1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.about-us-placeholder-image17 {
  width: 100px;
  height: 100px;
}
.about-us-content5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.about-us-title1 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.about-us-text28 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.about-us-text29 {
  font-size: 12px;
  text-align: center;
  font-family: "Poppins";
}
.about-us-text30 {
  font-size: 14px;
  text-align: center;
  font-family: "Poppins";
}
.about-us-social-icons1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.about-us-link1 {
  display: contents;
}
.about-us-icon1 {
  text-decoration: none;
}
.about-us-card2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.about-us-placeholder-image18 {
  width: 100px;
  height: 100px;
}
.about-us-content6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.about-us-title2 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.about-us-text31 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.about-us-text32 {
  font-size: 12px;
  text-align: center;
  font-family: "Poppins";
}
.about-us-text33 {
  font-size: 14px;
  text-align: center;
  font-family: "Poppins";
}
.about-us-social-icons2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.about-us-link2 {
  display: contents;
}
.about-us-icon3 {
  text-decoration: none;
}
.about-us-container3 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.about-us-card3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.about-us-placeholder-image19 {
  width: 100px;
  height: 100px;
}
.about-us-content7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.about-us-title3 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.about-us-text34 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.about-us-text35 {
  font-size: 12px;
  text-align: center;
  font-family: "Poppins";
}
.about-us-text36 {
  font-size: 14px;
  text-align: center;
  font-family: "Poppins";
}
.about-us-social-icons3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.about-us-link3 {
  display: contents;
}
.about-us-icon5 {
  text-decoration: none;
}
.about-us-card4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 500px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.about-us-placeholder-image20 {
  width: 100px;
  height: 100px;
}
.about-us-content8 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.about-us-title4 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.about-us-text37 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.about-us-text38 {
  font-size: 12px;
  text-align: center;
  font-family: "Poppins";
}
.about-us-text39 {
  font-size: 14px;
  text-align: center;
  font-family: "Poppins";
}
.about-us-social-icons4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.about-us-link4 {
  display: contents;
}
.about-us-icon7 {
  text-decoration: none;
}
@media(max-width: 1600px) {
  .about-us-text34 {
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .about-us-text35 {
    font-size: 12px;
    font-family: Poppins;
  }
  .about-us-text36 {
    font-size: 14px;
    font-family: Poppins;
  }
}
@media(max-width: 1200px) {
  .about-us-image {
    top: 47px;
    left: 33px;
  }
}
@media(max-width: 991px) {
  .about-us-header76 {
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .about-us-max-width1 {
    flex-direction: column;
  }
  .about-us-column1 {
    width: 100%;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  .about-us-content1 {
    width: 100%;
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .about-us-column2 {
    width: 100%;
    margin-top: 0px;
    flex-direction: row;
    justify-content: center;
  }
  .about-us-placeholder-image10 {
    width: 400px;
  }
  .about-us-placeholder-image11 {
    width: 400px;
  }
  .about-us-placeholder-image12 {
    width: 400px;
  }
  .about-us-column3 {
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
  .about-us-placeholder-image13 {
    width: 400px;
  }
  .about-us-placeholder-image14 {
    width: 400px;
    display: none;
  }
  .about-us-placeholder-image15 {
    width: 400px;
  }
  .about-us-placeholder-image16 {
    width: 400px;
  }
  .about-us-content3 {
    gap: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 767px) {
  .about-us-column1 {
    position: relative;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .about-us-image {
    top: 30px;
    left: 16px;
    width: 61px;
    height: 62px;
    position: absolute;
    align-self: flex-end;
  }
  .about-us-text10 {
    width: 100%;
    font-style: normal;
    text-align: left;
    font-family: Poppins;
    font-weight: 500;
  }
  .about-us-text11 {
    font-size: 32px;
    text-align: center;
  }
  .about-us-text12 {
    font-size: 32px;
  }
  .about-us-text13 {
    font-size: 32px;
  }
  .about-us-text15 {
    text-align: left;
  }
  .about-us-content1 {
    align-self: stretch;
  }
  .about-us-placeholder-image10 {
    width: 100%;
    height: 250px;
  }
  .about-us-placeholder-image11 {
    width: 100%;
    height: 250px;
  }
  .about-us-placeholder-image12 {
    width: 100%;
    height: 250px;
  }
  .about-us-column3 {
    align-self: stretch;
    margin-left: 0;
  }
  .about-us-placeholder-image13 {
    width: 100%;
    height: 250px;
  }
  .about-us-placeholder-image14 {
    width: 100%;
    height: 250px;
    display: none;
  }
  .about-us-placeholder-image15 {
    height: 250px;
  }
  .about-us-placeholder-image16 {
    height: 250px;
    display: flex;
  }
  .about-us-row {
    align-items: center;
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .about-us-column1 {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .about-us-image {
    top: 9px;
    left: 2px;
    width: 49px;
    height: 49px;
  }
  .about-us-text10 {
    text-align: left;
  }
  .about-us-text11 {
    text-align: center;
  }
  .about-us-text15 {
    text-align: left;
  }
  .about-us-column2 {
    width: 100%;
  }
  .about-us-placeholder-image14 {
    display: none;
  }
  .about-us-text26 {
    text-align: center;
  }
  .about-us-text27 {
    text-align: center;
  }
  .about-us-container2 {
    flex-direction: column;
  }
  .about-us-container3 {
    flex-direction: column;
  }
}
</style>