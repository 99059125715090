<template>
  <div class="newsletter-verify-container">
    <app-navbar4 rootClassName="navbar4-root-class-name15"></app-navbar4>
    <div class="newsletter-verify-header76">
      <div class="newsletter-verify-max-width thq-section-max-width">
        <div class="newsletter-verify-column thq-section-padding">
          <img
            alt="image"
            src="/yubaro-slash-200w.webp"
            class="newsletter-verify-image"
          />
          <h1 id="aboutH" class="newsletter-verify-text thq-heading-1">
            <span>Please verify your email address</span>
            <br />
          </h1>
          <p class="newsletter-verify-text03 thq-body-large">
            <span>
              Thank you for signing up to our newsletter. We have sent a
              verification email to your email address. Please click on the link
              to verify your email address and complete the sign up process.
            </span>
            <br />
            <br />
            <span>
              If you haven&apos;t already, make sure you download our app and
              create an account in order to access all the latest offers available
              on our marketplace: 
            </span>
            <br />
          </p>
          <div class="newsletter-verify-actions">
            <button class="thq-button-filled newsletter-verify-button">
              <span class="newsletter-verify-text09 thq-body-small">
                Download our App
              </span>
            </button>
            <button class="thq-button-outline newsletter-verify-button1">
              <span class="newsletter-verify-text10 thq-body-small">
                View on Web
              </span>
            </button>
          </div>
        </div>
        <about-image-section
          rootClassName="about-image-section-root-class-name"
        ></about-image-section>
      </div>
    </div>
    <contact-us-bottom></contact-us-bottom>
    <app-footer></app-footer>
    <cookie-consent-banner
      rootClassName="cookie-consent-banner-root-class-name11"
    ></cookie-consent-banner>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AboutImageSection from '../components/about-image-section'
import ContactUsBottom from '../components/contact-us-bottom'
import AppFooter from '../components/footer'
import CookieConsentBanner from '../components/cookie-consent-banner'

export default {
  name: 'NewsletterVerify',
  props: {},
  components: {
    AppNavbar4,
    AboutImageSection,
    ContactUsBottom,
    AppFooter,
    CookieConsentBanner,
  },
  metaInfo: {
    title: 'Newsletter - Please verify your email address - Yubaro',
    meta: [
      {
        name: 'description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        property: 'og:title',
        content: 'Newsletter - Please verify your email address - Yubaro',
      },
      {
        property: 'og:description',
        content:
          'Yubaro is an alternative asset marketplace giving users access to the gold, property, fine art, rare cars, whiskey casks and wine investments.',
      },
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  },
}
</script>

<style scoped>
.newsletter-verify-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #e0d5bb;
}
.newsletter-verify-header76 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
}
.newsletter-verify-max-width {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.newsletter-verify-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.newsletter-verify-image {
  top: 48px;
  left: 30px;
  width: 95px;
  height: 97px;
  z-index: 0;
  position: absolute;
  object-fit: cover;
}
.newsletter-verify-text {
  z-index: 100;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 1.2;
}
.newsletter-verify-text03 {
  font-family: "Poppins";
}
.newsletter-verify-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.newsletter-verify-text09 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
}
.newsletter-verify-text10 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
}
@media(max-width: 1600px) {
  .newsletter-verify-text {
    line-height: 1.1;
  }
  .newsletter-verify-text03 {
    font-family: Poppins;
  }
}
@media(max-width: 991px) {
  .newsletter-verify-header76 {
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .newsletter-verify-max-width {
    flex-direction: column;
  }
  .newsletter-verify-column {
    width: 100%;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
}
@media(max-width: 767px) {
  .newsletter-verify-column {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .newsletter-verify-image {
    top: 27px;
    left: 18px;
    width: 61px;
    height: 62px;
  }
  .newsletter-verify-text {
    width: 100%;
    font-size: 36px;
    text-align: left;
    line-height: 0.8;
  }
  .newsletter-verify-text03 {
    text-align: center;
  }
  .newsletter-verify-actions {
    width: 100%;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .newsletter-verify-column {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .newsletter-verify-image {
    top: 9px;
    left: 3px;
    width: 49px;
    height: 49px;
  }
  .newsletter-verify-text {
    font-size: 36px;
    text-align: left;
  }
  .newsletter-verify-text03 {
    text-align: center;
  }
  .newsletter-verify-actions {
    flex-direction: column;
  }
  .newsletter-verify-button {
    width: 100%;
  }
  .newsletter-verify-button1 {
    width: 100%;
  }
}
</style>
