<template>
  <div
    class="contact-us-bottom-contact20 thq-section-padding"
    v-bind:class="rootClassName"
  >
    <div class="contact-us-bottom-max-width thq-section-max-width">
      <div class="contact-us-bottom-list-item10"></div>
      <div class="contact-us-bottom-section-title">
        <span class="contact-us-bottom-text thq-body-small">
          Our team is here to assist you.
        </span>
        <div class="contact-us-bottom-content">
          <h2 class="contact-us-bottom-text01 thq-heading-2">Contact Us</h2>
          <p class="contact-us-bottom-text02 thq-body-large">
            Feel free to reach out to us for any inquiries or feedback.
          </p>
        </div>
      </div>
      <div class="contact-us-bottom-row">
        <div class="contact-us-bottom-content1">
          <svg
            viewBox="0 0 1024 1024"
            class="contact-us-bottom-icon thq-icon-medium"
          >
            <path
              d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"
            ></path>
          </svg>
          <div class="contact-us-bottom-contact-info">
            <div class="contact-us-bottom-content2">
              <h3 class="contact-us-bottom-text03 thq-heading-3">Email</h3>
              <router-link to="/contact" class="contact-us-bottom-navlink">
                <p class="contact-us-bottom-text04 thq-body-large">
                  Click Here to contact us
                </p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="contact-us-bottom-content3">
          <svg
            viewBox="0 0 1024 1024"
            class="contact-us-bottom-icon2 thq-icon-medium"
          >
            <path
              d="M282 460q96 186 282 282l94-94q20-20 44-10 72 24 152 24 18 0 30 12t12 30v150q0 18-12 30t-30 12q-300 0-513-213t-213-513q0-18 12-30t30-12h150q18 0 30 12t12 30q0 80 24 152 8 26-10 44z"
            ></path>
          </svg>
          <div class="contact-us-bottom-contact-info1">
            <div class="contact-us-bottom-content4">
              <h3 class="contact-us-bottom-text05 thq-heading-3">Phone</h3>
              <p class="contact-us-bottom-text06 thq-body-large">
                <span>
                  Our phone lines are available
                  <span v-html="rawg12r"></span>
                </span>
                <br />
                <span>Monday to Friday, 10am to 5pm.</span>
              </p>
              <a href="tel:+442081547034" class="contact-us-bottom-link">
                <p class="contact-us-bottom-text10 thq-body-large">
                  +44 (0) 208 154 7034
                </p>
              </a>
            </div>
          </div>
        </div>
        <div class="contact-us-bottom-content5">
          <svg
            viewBox="0 0 1024 1024"
            class="contact-us-bottom-icon4 thq-icon-medium"
          >
            <path
              d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"
            ></path>
          </svg>
          <div class="contact-us-bottom-contact-info2">
            <div class="contact-us-bottom-content6">
              <h3 class="contact-us-bottom-text11 thq-heading-3">Address</h3>
              <p class="contact-us-bottom-text12 thq-body-large">
                By appointment only
              </p>
              <p class="contact-us-bottom-text13 thq-body-large">
                <span>
                  Fourth Floor,
                  <span v-html="raw2ils"></span>
                </span>
                <br />
                <span>70 Baker Street,</span>
                <br />
                <span>London, W1U 7DL</span>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUsBottom',
  props: {
    rootClassName: String,
  },
  data() {
    return {
      rawg12r: ' ',
      raw2ils: ' ',
    }
  },
}
</script>

<style scoped>
.contact-us-bottom-contact20 {
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.contact-us-bottom-max-width {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-us-bottom-list-item10 {
  gap: var(--dl-space-space-unit);
  height: 20px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-imageradius);
  margin-bottom: 0px;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgba(163,89,27,1) 0%, rgba(241,184,96,1) 58%, rgba(244,188,98,1) 74%, rgba(199,130,96,1) 100%);;
}
.contact-us-bottom-section-title {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.contact-us-bottom-text {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  font-family: "Poppins";
}
.contact-us-bottom-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-us-bottom-text01 {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
.contact-us-bottom-text02 {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  text-align: center;
  font-family: "Poppins";
}
.contact-us-bottom-row {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
.contact-us-bottom-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-us-bottom-icon {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
}
.contact-us-bottom-contact-info {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-us-bottom-content2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
}
.contact-us-bottom-text03 {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 500;
}
.contact-us-bottom-navlink {
  display: contents;
}
.contact-us-bottom-text04 {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  text-align: center;
  font-family: "Poppins";
  text-decoration: none;
}
.contact-us-bottom-content3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-us-bottom-icon2 {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
}
.contact-us-bottom-contact-info1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-us-bottom-content4 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
}
.contact-us-bottom-text05 {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 500;
}
.contact-us-bottom-text06 {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 500;
}
.contact-us-bottom-link {
  display: contents;
}
.contact-us-bottom-text10 {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  text-align: center;
  font-family: "Poppins";
  text-decoration: none;
}
.contact-us-bottom-content5 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-us-bottom-icon4 {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
}
.contact-us-bottom-contact-info2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-us-bottom-content6 {
  gap: var(--dl-space-space-halfunit);
  height: 184px;
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
}
.contact-us-bottom-text11 {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  align-self: stretch;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 500;
}
.contact-us-bottom-text12 {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 500;
}
.contact-us-bottom-text13 {
  fill: var(--dl-color-yubarobrandcolours-primarytext);
  color: var(--dl-color-yubarobrandcolours-primarytext);
  text-align: center;
  font-family: "Poppins";
}
.contact-us-bottom-root-class-name {
  width: 100%;
}
@media(max-width: 991px) {
  .contact-us-bottom-root-class-name {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .contact-us-bottom-contact20 {
    height: 897px;
  }
  .contact-us-bottom-row {
    flex-direction: column;
  }
  .contact-us-bottom-root-class-name {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .contact-us-bottom-contact20 {
    height: 944px;
  }
  .contact-us-bottom-text01 {
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .contact-us-bottom-row {
    align-items: stretch;
  }
  .contact-us-bottom-root-class-name {
    width: 100%;
  }
}
</style>
