<template>
  <div class="contact-form-container" v-bind:class="rootClassName">
    <div v-if="formSubmitted">
      <p class="success-message">Email sent successfully! Someone from our team will contact you shortly.</p>
    </div>
    <form v-else autocomplete="on" class="contact-form-form" @submit.prevent="submitForm">
      <div class="contact-form-input">
        <label for="contact-form-2-name" class="contact-form-text thq-body-small">
          {{ text }}
        </label>
        <input
          type="text"
          id="contact-form-2-name"
          v-model="formData.name"
          :placeholder="textInputPlaceholder"
          autocomplete="name"
          class="contact-form-text-input thq-input"
        />
      </div>
      <div class="contact-form-input1">
        <label for="contact-form-2-email" class="contact-form-text1 thq-body-small">
          {{ text1 }}
        </label>
        <input
          type="email"
          id="contact-form-2-email"
          v-model="formData.email"
          :placeholder="textInputPlaceholder1"
          autocomplete="email"
          class="contact-form-text-input1 thq-input"
        />
      </div>
      <div class="contact-form-input2">
        <label for="contact-form-2-message" class="contact-form-text2 thq-body-small">
          {{ text2 }}
        </label>
        <textarea
          id="contact-form-2-message"
          v-model="formData.message"
          rows="3"
          :placeholder="textareaPlaceholder"
          class="contact-form-textarea thq-input"
        ></textarea>
      </div>
      <button id="submitContactForm" type="submit" class="contact-form-button thq-button-filled">
        <span class="contact-form-action1 thq-body-small">{{ action1 }}</span>
      </button>
      <div v-if="statusMessage" :class="{'success-message': isSuccess, 'error-message': !isSuccess}" class="status-message">
        {{ statusMessage }}
      </div>
      <div class="contact-form-container1">
        <span class="contact-form-text3">This site is protected by reCAPTCHA, and the Google</span>
        <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener" class="contact-form-link">Privacy Policy</a>
        <span class="contact-form-text4"> and </span>
        <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer noopener" class="contact-form-link1">Terms of Service</a>
        <span class="contact-form-text5"> apply</span>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ContactForm',
  data() {
    return {
      formData: {
        name: '',
        email: '',
        message: ''
      },
      statusMessage: '',
      isSuccess: false,
      formSubmitted: false // New data property to check if form is submitted successfully
    }
  },
  props: {
    textareaPlaceholder: {
      type: String,
      default: 'Enter your message',
    },
    textInputPlaceholder1: {
      type: String,
      default: 'Email',
    },
    rootClassName: String,
    textInputPlaceholder: {
      type: String,
      default: 'Name',
    },
    text2: {
      type: String,
      default: 'Message',
    },
    text: {
      type: String,
      default: 'Name',
    },
    text1: {
      type: String,
      default: 'Email',
    },
    action1: {
      type: String,
      default: 'Submit',
    },
  },
  methods: {
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },
    validateForm() {
      if (!this.formData.name) {
        this.statusMessage = 'Name is required.'
        return false
      }
      if (!this.formData.email) {
        this.statusMessage = 'Email address is required.'
        return false
      }
      if (!this.validateEmail(this.formData.email)) {
        this.statusMessage = 'Please enter a valid email address.'
        return false
      }
      if (!this.formData.message) {
        this.statusMessage = 'Message is required.'
        return false
      }
      return true
    },
    async submitForm() {
      this.statusMessage = ''

      if (!this.validateForm()) {
        this.isSuccess = false
        return
      }

      try {
        const recaptchaToken = await grecaptcha.execute('6LfN2O8pAAAAAMOWqW9Nt5ykRACiVZH_nbUCRPfr', { action: 'submit' })

        const response = await fetch('https://yubaro-8539.twil.io/hotaru', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: this.formData.email,
            subject: this.formData.name,
            body: this.formData.message,
            recaptchaToken: recaptchaToken
          })
        })

        const result = await response.json()
        if (result.success) {
          this.formSubmitted = true // Set formSubmitted to true
        } else {
          this.statusMessage = `Failed to send email: ${result.message}`
          this.isSuccess = false
        }
      } catch (error) {
        console.error('Error:', error)
        this.statusMessage = 'An error occurred while sending the email.'
        this.isSuccess = false
      }
    }
  }
}
</script>

<style scoped>
.contact-form-container {
  display: flex;
  position: relative;
}
.contact-form-form {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.contact-form-input {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-form-text {
  font-family: "Poppins";
}
.contact-form-text-input {
  width: 100%;
  height: var(--dl-size-size-small);
  border-width: 1px;
}
.contact-form-input1 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-form-text1 {
  font-family: "Poppins";
}
.contact-form-text-input1 {
  width: 100%;
  height: var(--dl-size-size-small);
  border-width: 1px;
}
.contact-form-input2 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-form-text2 {
  font-family: "Poppins";
}
.contact-form-textarea {
  width: 100%;
  height: var(--dl-size-size-medium);
  border-width: 1px;
}
.contact-form-button {
  border-width: 0px;
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  background-color: transparent;
  background-image: linear-gradient(90deg, rgba(163,89,27,1) 0%, rgba(241,184,96,1) 58%, rgba(244,188,98,1) 74%, rgba(199,130,96,1) 100%);
}
.contact-form-action1 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
}
.contact-form-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
.contact-form-text3 {
  font-size: 10px;
  text-align: center;
}
.contact-form-link {
  color: var(--dl-color-yubarobrandcolours-primary);
  font-size: 10px;
  text-align: center;
  text-decoration: underline;
  margin-left:2px;
  margin-right:2px;
}
.contact-form-text4 {
  font-size: 10px;
  text-align: center;
}
.contact-form-link1 {
  fill: var(--dl-color-yubarobrandcolours-primary);
  color: var(--dl-color-yubarobrandcolours-primary);
  font-size: 10px;
  text-align: center;
  text-decoration: underline;
  margin-left:2px;
  margin-right:2px;
}
.contact-form-text5 {
  font-size: 10px;
  text-align: center;
}
.contact-form-root-class-name {
  width: 100%;
}
.status-message {
  margin-top: 1rem;
}
.success-message {
  color: green;
}
.error-message {
  color: red;
}
@media(max-width: 1600px) {
  .contact-form-root-class-name {
    width: 100%;
  }
}
@media(max-width: 991px) {
  .contact-form-form {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .contact-form-form {
    align-items: center;
  }
  .contact-form-input {
    align-items: flex-start;
  }
  .contact-form-text-input {
    border-width: 0px;
  }
  .contact-form-input1 {
    align-items: flex-start;
  }
  .contact-form-text-input1 {
    border-width: 0px;
  }
  .contact-form-input2 {
    align-items: flex-start;
  }
  .contact-form-container1 {
    flex-wrap: wrap;
  }
  .contact-form-link1 {
    color: var(--dl-color-yubarobrandcolours-primary);
    text-decoration: underline;
  }
}
</style>
